import { hasErrors } from '@/pages/Magic/utils/form';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/Card';
import { ErrorOverView } from '../../../components/ErrorOverview';
import { InfoPreview } from '../../../components/InfoPreview';
import { Section } from '../../../components/Section';
import { useBillingAddressSettings } from '../../../hooks/api';
import { errorTranslationKeyMap } from '../form';
import { BaseInformationFields } from './common';
import { formatPhone } from '@/pages/Magic/utils/formatters';
import { useIsMarketCode, useIsOrgType } from '../hook';
export function BaseInformationPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, BaseInformationFields);
    const isGermanMarket = useIsMarketCode('DE');
    const isType = useIsOrgType(values.types ?? []);
    const billingAddressSettings = useBillingAddressSettings();
    const country = billingAddressSettings.data?.countries.find(c => c.code === values.billingAddress?.country) ?? null;
    const state = country?.states.find(s => s.code === values.billingAddress?.state) ?? null;
    return (<Card title={t('pages.organization.form.baseInfo.headline')} onEdit={props.onEdit}>
            <ErrorOverView errors={errors} errorTranslationKeyMap={errorTranslationKeyMap}/>
            <div className="grid grid-cols-2 gap-2">
                <InfoPreview label={t('components.organizationForm.name')}>{values.name}</InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.additionalAddress')} className="col-start-1">
                    {values.billingAddress?.additionalAddress}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.streetNumber')} className="col-start-1">
                    {values.billingAddress?.street} {values.billingAddress?.number}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.cityZip')} className="col-start-1">
                    {values.billingAddress &&
            (values.billingAddress.zip || values.billingAddress.city) &&
            `${values.billingAddress.zip ?? ''} ${values.billingAddress.city ?? ''}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.country')} className="col-start-1">
                    {country && t(country.name)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.state')} className="col-start-1">
                    {state && t(state.name)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.tax.taxId')} className="col-start-2 row-start-1">
                    {values.taxId}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.tax.vatId')} className="col-start-2 row-start-2">
                    {values.vatId}
                </InfoPreview>
                {isGermanMarket && isType.carrier && (<InfoPreview label={t('disposal.carrierNumber.title')} className="col-start-2 row-start-3">
                        {values.carrierNumber}
                    </InfoPreview>)}
            </div>

            <hr className="my-6 border-divider"/>
            <Section title={t('components.organizationForm.contact.title')}>
                <InfoPreview label={t('components.organizationForm.contact.phone')}>
                    {formatPhone(values.phone)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.mobile')}>
                    {formatPhone(values.mobile)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.fax')}>
                    {formatPhone(values.fax)}
                </InfoPreview>

                <InfoPreview label={t('components.organizationForm.contact.email')} className="col-start-1">
                    {values.email}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.website')}>{values.web}</InfoPreview>
            </Section>
            <hr className="my-6 border-divider"/>
            <Section title={t('components.organizationForm.logo.title')}>
                {values.logo && 'url' in values.logo && values.logo.url.small ? (<img className="h-icon-size-lg w-auto overflow-hidden rounded object-center" src={values.logo.url.small} alt={values.logo.fileName}/>) : (<div className="flex h-[56px] w-[54%] items-center justify-center bg-light-gray-200 ">
                        <span className="font-copy-sm text-subdued">{t('pages.magic.organization.baseInfo.logo')}</span>
                    </div>)}
            </Section>
        </Card>);
}
