import { ProductUseCaseImage, uploadImage } from '@schuettflix/client-file';
import { DeleteIcon } from '@schuettflix/icons-react';
import { Dropzone, File, IconButton } from '@schuettflix/react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
function mapImageToVirtualFile(image) {
    if (!('url' in image))
        return null;
    return {
        name: image.fileName,
        type: 'image/png',
    };
}
export function ImageEdit(props) {
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(null);
    const [abortController, setAbortController] = useState(null);
    const { t } = useTranslation();
    async function upload(file) {
        try {
            const controller = new AbortController();
            setAbortController(controller);
            setError(null);
            setProgress(0);
            const uploaded = await uploadImage({
                file,
                meta: { productUseCase: ProductUseCaseImage.OrganizationLogo },
                onUploadProgressPercent: setProgress,
                signal: controller.signal,
            });
            props.onChange(uploaded.data);
            setUploadingFile(null);
            setProgress(null);
        }
        catch (e) {
            setError('Fehler beim Hochladen des Logos');
        }
    }
    const mapped = props.value ? mapImageToVirtualFile(props.value) : null;
    const previewFile = mapped ?? uploadingFile;
    return (<div>
            {previewFile ? (<>
                    {/* Already uploaded */}
                    {props.value && 'url' in props.value && (<div>
                            <div className="flex items-center gap-2">
                                <img className="h-icon-size-lg w-auto overflow-hidden rounded object-center" src={props.value.url.small} alt={props.value.fileName}/>
                                <IconButton type="button" icon={DeleteIcon} size="lg" onClick={() => props.onChange(null)}/>
                            </div>
                        </div>)}

                    {/* Uploading */}
                    {uploadingFile && (<File file={uploadingFile} upload={progress !== null
                    ? {
                        progress,
                        error: error ?? false,
                        onCancel: () => {
                            abortController?.abort();
                            setUploadingFile(null);
                            setProgress(null);
                        },
                        onRetry: () => {
                            upload(uploadingFile);
                        },
                    }
                    : undefined}/>)}
                </>) : (<Dropzone translations={{
                label: t('pages.magic.fileUploader.action.image.headline'),
                info: t('pages.magic.fileUploader.action.image.info'),
                drop: t('pages.magic.fileUploader.action.image.drop'),
                errors: {
                    invalidFileType: () => t('components.fileUploader.actions.error.fileType'),
                    invalidFileSize: () => t('components.fileUploader.actions.error.fileSize'),
                    maxOneFile: t('components.fileUploader.actions.error.maxOne'),
                },
            }} multiple={false} accept="image/*" onChange={files => {
                if (files[0]) {
                    setUploadingFile(files[0]);
                    upload(files[0]);
                }
            }}/>)}
            {error && <p className="text-critical">{error}</p>}
        </div>);
}
