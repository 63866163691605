import OrganizationFormPageReact from '@/pages/Organization/OrganizationFormPageReact';
import OrganizationListPage from '@/pages/Organization/OrganizationListPage';
import LocationList from '@/views/LocationList/LocationList';
import CertificateManagementListPage from '@/pages/CertificateManagement/CertificateManagementListPage';
import CertificateManagementEditPage from '@/pages/CertificateManagement/CertificateManagementEditPage';

export default [
    {
        path: 'organization',
        name: 'organization-list',
        component: OrganizationListPage,
        meta: {
            requiresAuth: true,
            requiredAbilities: ['listOrganizations'],
        },
        children: [
            {
                path: ':organizationId',
                name: 'organization-view',
                component: OrganizationFormPageReact,
                props: route => {
                    return {
                        ...route.params,
                        parentRoute: 'organization-list',
                    };
                },
            },
            {
                path: ':supplierOrganizationId/factory-management',
                name: 'admin-factory-management',
                component: LocationList,
                meta: {
                    requiredAbilities: ['haveFactoryManagement'],
                },
                props: route => {
                    return {
                        ...route.params,
                        parentRoute: 'organization-list',
                    };
                },
            },
            {
                path: ':organizationId/certificate-management',
                name: 'admin-certificate-management',
                component: CertificateManagementListPage,
                props: route => {
                    return {
                        ...route.params,
                        parentRoute: 'organization-list',
                    };
                },
                children: [
                    {
                        path: ':certificateId',
                        name: 'certificate-management-edit',
                        component: CertificateManagementEditPage,
                        props: true,
                    },
                ],
            },
        ],
    },
];
