import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiService from '@/services/Api/ApiService';
import { invalidateProductSchemaListQuery } from '@/pages/ProductManagement/AdvanceManagement/hooks/useProductSchemaList';
export function useCreateAttributeValue() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => apiService.post('/v1/attribute-values', data),
        onSuccess: () => {
            invalidateProductSchemaListQuery(queryClient);
        },
    });
}
