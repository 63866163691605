import { appAccounting } from '@schuettflix/app-accounting';
import { appOrderDetails, widgetOrderTransportList } from '@schuettflix/app-order-details';
import { installTransportServiceHooks, widgetTransportDriver, widgetTransportList } from '@schuettflix/app-transport';
import { accessToken, userLocale, gatewayUrl, baseUrl as baseUrlMonolith, baseUrlOrder, baseUrlOrderDetails, baseUrlTransport, baseUrlFulfillment, baseUrlConstructionProject, baseUrlOrderApp, baseUrlMonolithFrontend, } from './sflxAppsSharedStates';
import toaster from './services/Toaster';
import { getQueryParamManager } from './utils/queryParamManager';
import { getLd } from './services/LaunchDarkly';
export const appAccountingWC = appAccounting;
export const installSflxApps = ({ analyticsService }) => {
    const queryParamManager = getQueryParamManager();
    const getQueryParam = queryParamManager.getQueryParam.bind(queryParamManager);
    const setQueryParam = queryParamManager.setQueryParam.bind(queryParamManager);
    const getQueryParams = queryParamManager.getQueryParams.bind(queryParamManager);
    const setQueryParams = queryParamManager.setQueryParams.bind(queryParamManager);
    installTransportServiceHooks({
        accessToken,
        userLocale,
        baseUrlMonolith,
        baseUrlTransport,
    });
    /**
     * Transport Service
     */
    widgetTransportList.install({
        toaster,
        hostAppVersion: process.env.VUE_APP_VERSION,
        userLocale,
        accessToken,
        baseUrlMonolith,
        baseUrlTransport,
        getQueryParam,
        setQueryParam,
        getQueryParams,
        setQueryParams,
    });
    widgetTransportDriver.install({
        toaster,
        hostAppVersion: process.env.VUE_APP_VERSION,
        userLocale,
        accessToken,
        baseUrlMonolith,
        baseUrlTransport,
        getQueryParam,
        setQueryParam,
        getQueryParams,
        setQueryParams,
    });
    /**
     * Accounting Service
     */
    appAccountingWC.install({
        accessToken,
        gatewayUrl,
        userLocale,
        hostAppVersion: process.env.VUE_APP_VERSION,
        analytics: {
            service: analyticsService,
        },
    });
    /**
     * Order Service
     */
    const orderInstallProps = {
        accessToken,
        userLocale,
        toaster,
        baseUrlMonolith,
        baseUrlMonolithFrontend,
        baseUrlOrder,
        baseUrlOrderDetails,
        baseUrlFulfillment,
        baseUrlConstructionProject,
        baseUrlOrderApp,
        launchDarklyClient: getLd()?.client,
        launchDarklyClientId: window.launchDarklyClientId || '',
    };
    appOrderDetails.install(orderInstallProps);
    widgetOrderTransportList.install(orderInstallProps);
};
