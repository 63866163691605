import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiService from '@/services/Api/ApiService';
import { invalidateProductSchemaListQuery } from '@/pages/ProductManagement/AdvanceManagement/hooks/useProductSchemaList';
export function useUpdateAttributeValue() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => apiService.patch(`/v1/attribute-values/${data.attributeValueId}`, data),
        onSuccess: () => {
            invalidateProductSchemaListQuery(queryClient);
        },
    });
}
