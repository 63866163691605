import ApiService from '@/services/Api/ApiService';
import { MINUTE } from '@/services/utils/time';
import store from '@/store';
class FeatureFlagsService {
    flags = {
        automatic_accompanying_document_creation: false,
        block_orders_for_new_organizations: false,
        construction_site_update_original_delivery_note_required: false,
        show_disposal_tab_on_organization: false,
        disposal_project_order: false,
        subcontractor_support: false,
        disposal_certificate_management: false,
    };
    interval;
    listeners = new Set();
    async refresh() {
        if (!store.getters['user/isLoggedIn'])
            return;
        try {
            const response = await ApiService.get('/featureflag');
            this.flags = response.data;
            this.notify();
        }
        catch (_) {
            // noop
        }
    }
    getFlags() {
        return Object.freeze(this.flags);
    }
    subscribe(listener) {
        this.listeners.add(listener);
        return () => this.listeners.delete(listener);
    }
    notify() {
        this.listeners.forEach(listener => listener());
    }
    setupPolling() {
        this.refresh();
        this.interval && clearInterval(this.interval);
        this.interval = window.setInterval(() => this.refresh(), MINUTE);
    }
}
export default new FeatureFlagsService();
