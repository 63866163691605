import { SUPPORTED_UNITS_LABEL, } from '@/modules/constructionProjects/api/position-service-interfaces';
import { ProgressBar } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
function getProductCategoryTranslationKey(category) {
    switch (category) {
        case 'TRANSPORT_SERVICE':
        case 'MATERIAL_SERVICE':
            return 'components.orderItemBlock.genericOrder.service';
        case 'MATERIAL_TRANSPORT':
            return 'components.orderItemBlock.genericOrder.material_transport';
        case 'MATERIAL':
            return 'components.orderItemBlock.genericOrder.material';
        case 'TRANSPORT':
            return 'components.orderItemBlock.genericOrder.transport';
    }
}
export function GenericPositionProduct({ positionProduct, productGroupType }) {
    const { t, i18n } = useTranslation();
    const formatNumber = (value) => {
        const locale = i18n.resolvedLanguage;
        return value.toLocaleString(locale);
    };
    return (<div className="flex flex-col gap-1">
            <span className="font-copy-sm">
                {(productGroupType === 'FRANCO' || productGroupType === 'MERCHANT') &&
            positionProduct.category === 'MATERIAL' ? (<>
                        {[
                t(getProductCategoryTranslationKey('MATERIAL')),
                t(getProductCategoryTranslationKey('TRANSPORT')),
            ].join(', ')}
                    </>) : (t(getProductCategoryTranslationKey(positionProduct.category)))}
            </span>
            <span className="font-copy-md-strong block">{positionProduct.name}</span>
            {!!positionProduct.quota && (<ProgressBar key={positionProduct.id} min={0} max={positionProduct.quota} size="md" primary={positionProduct.fulfilledQuantity} secondary={positionProduct.reservedQuantity} before={<span className="whitespace-nowrap">
                            {formatNumber(positionProduct.fulfilledQuantity)}{' '}
                            {t(`units.${SUPPORTED_UNITS_LABEL[positionProduct.unit]}`)}
                        </span>} after={<span className="whitespace-nowrap">
                            {formatNumber(positionProduct.quota)}{' '}
                            {t(`units.${SUPPORTED_UNITS_LABEL[positionProduct.unit]}`)}
                        </span>}/>)}
        </div>);
}
