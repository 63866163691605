<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong">
            {{ $t('pages.homeV2.documentsTitle') }}
        </p>
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.documentsDescription') }}
        </p>

        <div class="grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr,1fr,1fr]">
            <StatusCard
                v-if="invoiceCount"
                icon="InvoiceIcon"
                :title="$t('pages.homeV2.invoiceCardTitle')"
                :description="$tc('pages.homeV2.invoiceCardDescription', invoiceCount)"
                :count="invoiceCount"
                @click="goToInvoiceListPage()"
            />
            <StatusCard
                v-if="creditNoteCount"
                icon="CreditIcon"
                :title="$t('pages.homeV2.creditNoteCardTitle')"
                :description="
                    $tc(
                        selectCreditNoteDocKey({
                            creditNote: `pages.homeV2.creditNoteDocCardDescription.creditNote`,
                            selfBilling: `pages.homeV2.creditNoteDocCardDescription.selfBilling`,
                        }),
                        creditNoteCount
                    )
                "
                :count="creditNoteCount"
                @click="goToCreditNotePage()"
            />
        </div>
        <EmptyState
            v-if="!invoiceCount && !creditNoteCount"
            class="mb-4"
            icon="DocumentEmptyIcon"
            :text="$t('pages.homeV2.noNewDocuments')"
        />
    </div>
</template>

<script>
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { useLd } from '@/services/LaunchDarkly';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { ensurePHPTimestamp } from '@/services/utils/date';
import endOfToday from 'date-fns/end_of_today';
import startOfDay from 'date-fns/start_of_day';
import subDays from 'date-fns/sub_days';

import EmptyState from '@/pages/Home/components/EmptyState';
import StatusCard from '@/pages/Home/components/StatusCard';
import AccountingDocumentsApi from '@/services/Api/AccountingDocuments';

const POLLING_INTERVAL = 60000;

export default {
    name: 'HomeSectionDocuments',
    components: {
        EmptyState,
        StatusCard,
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        const isOrganizationAccountingEnabled = useLd('accounting-ui-for-organization-admins');
        const creditNoteDocument = selectCreditNoteDocKey({
            creditNote: `credit_note`,
            selfBilling: `self_billing_invoice`,
        });
        return {
            creditNoteDocument,
            isOrganizationAccountingEnabled,
            selectCreditNoteDocKey,
        };
    },
    data() {
        return {
            invoiceCount: null,
            creditNoteCount: null,
        };
    },
    mounted() {
        this.refreshData();
        this.$gracefulInterval(this.refreshData.bind(this), POLLING_INTERVAL);
    },
    methods: {
        async refreshData() {
            if (!this.$can('haveReceipts')) {
                return;
            }

            this.refreshInvoiceCount();
            this.refreshCreditNoteCount();
        },

        async refreshInvoiceCount() {
            try {
                const res = await AccountingDocumentsApi.filter({
                    // TODO: Add "overdue" once the api is working
                    // https://linear.app/schuettflix/issue/CASH-2446/doctrineormqueryqueryexception-%5Bsemantical-error%5D-line-0-col-1447-near
                    documentStatus: ['created'],
                    documentType: 'invoice',
                    countOnly: true,
                });
                this.invoiceCount = res.data.count;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async refreshCreditNoteCount() {
            try {
                const res = await AccountingDocumentsApi.filter({
                    // TODO: Add "overdue" once the api is working
                    // https://linear.app/schuettflix/issue/CASH-2446/doctrineormqueryqueryexception-%5Bsemantical-error%5D-line-0-col-1447-near
                    documentStatus: ['created'],
                    documentType: this.creditNoteDocument,
                    countOnly: true,
                });
                this.creditNoteCount = res.data.count;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        goToInvoiceListPage() {
            if (this.isOrganizationAccountingEnabled) {
                this.$router.push({
                    name: this.$root.findRouteName('management__accounting'),
                    query: { 'documentStatus[]': 'created', documentType: 'invoice' },
                });
            } else {
                this.$router
                    .push({
                        name: this.$root.findRouteName('management__documents'),
                        query: { documents: assembleQueryFilter({ type: 'invoice', status: 'created' }) },
                    })
                    .catch(() => {});
            }
        },

        goToCreditNotePage() {
            const start = startOfDay(subDays(Date.now(), 3));
            const end = endOfToday();
            if (this.isOrganizationAccountingEnabled) {
                this.$router.push({
                    name: this.$root.findRouteName('management__accounting'),
                    query: {
                        'documentStatus[]': 'created',
                        documentType: this.creditNoteDocument,
                    },
                });
            } else {
                this.$router
                    .push({
                        name: this.$root.findRouteName('management__documents'),
                        query: {
                            documents: assembleQueryFilter({
                                type: this.creditNoteDocument,
                                startCreated: ensurePHPTimestamp(start.valueOf),
                                endCreated: ensurePHPTimestamp(end.valueOf),
                            }),
                        },
                    })
                    .catch(() => {});
            }
        },
    },
};
</script>
