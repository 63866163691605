import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiService from '@/services/Api/ApiService';
import { invalidateProductSchemaListQuery } from '@/pages/ProductManagement/AdvanceManagement/hooks/useProductSchemaList';
export function useDeleteAttributeValue(attributeValueId) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => apiService.delete(`/v1/attribute-values/${attributeValueId}`, undefined),
        onSuccess: () => {
            invalidateProductSchemaListQuery(queryClient);
        },
    });
}
