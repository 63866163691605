import ApiService from './ApiService';
function cleanParams(data) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
        if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
            continue;
        }
        if (Array.isArray(value)) {
            const k = `${key}[]`;
            params.delete(k);
            for (const v of value) {
                if (typeof v === 'string') {
                    params.append(k, v);
                }
            }
            continue;
        }
        params.set(key, value.toString());
    }
    return params;
}
class AccountingDocumentsApi {
    apiService = ApiService;
    async filter(filter) {
        const params = { page: 1, perPage: 25, ...filter };
        return await this.apiService.get(`/finance/v1/platform/accounting-documents?${cleanParams(params)}`);
    }
}
export default new AccountingDocumentsApi();
