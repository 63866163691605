import { useTranslation } from 'react-i18next';
import { ContextMenu, LoadingSpinner } from '@schuettflix/react-components';
import { CancelIcon, EditIcon, EurosIcon, SettingsIcon } from '@schuettflix/icons-react';
import { useCloseGenericPositionById } from '../hooks/useCloseGenericPositionById';
import { invalidateGetGenericPositionDetailsByIdQuery } from '@/modules/constructionProjects/api/shared/getGenericPositionDetailsById';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { useState } from 'react';
import { asyncDelay } from '@/services/utils';
import Toaster from '@/services/Toaster';
import { useActivateGenericPositionById } from '../hooks/useActivateGenericPositionById';
import { useInactivateGenericPositionById } from '../hooks/useInactivateGenericPositionById';
import { useGenericPositionBrowser } from '@/modules/constructionProjects/hooks/useGenericPositionBrowser';
import { useGenericPositionDetailsById } from '../../../shared/ConstructionProjectGenericPositionDetailsPage/hooks/useGenericPositionDetailsById';
export function GenericPositionAdminActions({ genericPositionId }) {
    const { t } = useTranslation();
    const closeGenericPosition = useCloseGenericPositionById(genericPositionId);
    const activateGenericPosition = useActivateGenericPositionById(genericPositionId);
    const inactivateGenericPosition = useInactivateGenericPositionById(genericPositionId);
    const position = useGenericPositionDetailsById(genericPositionId);
    const queryClient = useQueryClient();
    const [openModal, setOpenModal] = useState(false);
    const { openEditPositionSettings, openEditPositionProducts, openEditPositionTerms } = useGenericPositionBrowser();
    // Confirm closing shows a loading indicator optimistically (2 secs or so) for monolith-service-sync reasons
    const [fakeLoading, setFakeLoading] = useState(false);
    return position.data && position.data.position.status !== 'CLOSED' ? (<>
            <ContextMenu align="left" visibility="visible">
                <ContextMenu.Item icon={EditIcon} id="editGenericPositionProducts" label={t('pages.genericPosition.details.contextMenu.editGenericPositionProducts')} onClick={() => openEditPositionProducts(position.data.position.id)}/>
                <ContextMenu.Item icon={SettingsIcon} id="editGenericPositionSettings" label={t('pages.genericPosition.details.contextMenu.editGenericPositionSettings')} onClick={() => openEditPositionSettings(position.data.position.id)}/>
                <ContextMenu.Item icon={EurosIcon} id="editGenericPositionTerms" label={t('pages.genericPosition.details.contextMenu.editGenericPositionTerms')} onClick={() => openEditPositionTerms(position.data.position.id)}/>
                {position.data.position.active ? (<ContextMenu.Item icon={SettingsIcon} id="inactivateGenericPosition" disabled={inactivateGenericPosition.isLoading} label={t('pages.genericPosition.details.contextMenu.inactivateGenericPosition')} onClick={async () => {
                try {
                    await Promise.all([inactivateGenericPosition.mutateAsync(), await asyncDelay(1000)]);
                    invalidateGetGenericPositionDetailsByIdQuery(queryClient, genericPositionId);
                    Toaster.success(t('pages.genericPosition.details.inactivateGenericPosition.success', {
                        number: position.data?.position.positionNumber,
                    }));
                }
                catch (error) {
                    Toaster.error(t('pages.genericPosition.details.inactivateGenericPosition.error'));
                }
            }}/>) : (<ContextMenu.Item icon={SettingsIcon} id="activateGenericPosition" disabled={activateGenericPosition.isLoading} label={t('pages.genericPosition.details.contextMenu.activateGenericPosition')} onClick={async () => {
                try {
                    await Promise.all([activateGenericPosition.mutateAsync(), await asyncDelay(1000)]);
                    invalidateGetGenericPositionDetailsByIdQuery(queryClient, genericPositionId);
                    Toaster.success(t('pages.genericPosition.details.activateGenericPosition.success'));
                }
                catch (error) {
                    Toaster.error(t('pages.genericPosition.details.activateGenericPosition.error'));
                }
            }}/>)}
                <ContextMenu.Item icon={CancelIcon} id="closeGenericPosition" disabled={closeGenericPosition.isLoading} label={t('pages.genericPosition.details.contextMenu.closeGenericPosition')} onClick={() => {
            setOpenModal(true);
        }}/>
            </ContextMenu>
            <ModalTrigger isOpen={openModal} onOpenChange={() => setOpenModal(!openModal)}>
                <Modal>
                    {({ close }) => (<>
                            <Modal.Heading onAbort={close}>
                                {t('pages.genericPosition.details.contextMenu.closeGenericPosition')}
                            </Modal.Heading>
                            <Modal.Content>
                                {t('pages.genericPosition.details.closeGenericPositionModal.content')}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onPress={close} prominence="secondary">
                                    {t('pages.genericPosition.details.closeGenericPositionModal.cancel')}
                                </Button>
                                <Button prominence="primary" leadingSlot={closeGenericPosition.isLoading || fakeLoading ? <LoadingSpinner /> : undefined} isDisabled={closeGenericPosition.isLoading || fakeLoading} onPress={async () => {
                setFakeLoading(true);
                try {
                    await Promise.all([
                        closeGenericPosition.mutateAsync(),
                        await asyncDelay(2000),
                    ]);
                    invalidateGetGenericPositionDetailsByIdQuery(queryClient, genericPositionId);
                    close();
                    Toaster.success(t('pages.genericPosition.details.closeGenericPosition.success', {
                        number: position.data?.position.positionNumber,
                    }));
                }
                catch (error) {
                    Toaster.error(t('pages.genericPosition.details.closeGenericPosition.error'));
                }
                finally {
                    setFakeLoading(false);
                }
            }}>
                                    {t('pages.genericPosition.details.contextMenu.closeGenericPosition')}
                                </Button>
                            </Modal.Actions>
                        </>)}
                </Modal>
            </ModalTrigger>
        </>) : null;
}
