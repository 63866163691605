<template>
    <LayoutPage class="product-management-page" screen-name="platform-productmanagement" :child-routes="childRoutes">
        <div slot="pageTitle">{{ $t('pages.productManagement.title') }}</div>

        <div
            class="product-management-page__container flex justify-center gap-[15px]"
            data-test="product-management-page-cards"
        >
            <Card
                v-for="productManagementItem in productManagementItems"
                :key="productManagementItem.name"
                spaceless
                class="product-management-page__card"
                clickable
                @click="openRoute(productManagementItem.route, productManagementItem.name)"
            >
                <div class="product-management-page__card-title-container">
                    <div class="product-management-page__card-icon">
                        <NavigationIcon :icon="productManagementItem.icon" class="icon--inline" />
                    </div>
                    <Words bold block>
                        {{ $t(`pages.productManagement.tiles.titles.${productManagementItem.name}`) }}
                    </Words>
                </div>
                <Words block small class="product-management-page__description">
                    {{ $t(`pages.productManagement.tiles.descriptions.${productManagementItem.name}`) }}
                </Words>
                <Button
                    class="product-management-page__button"
                    @click.stop="openRoute(productManagementItem.route, productManagementItem.name)"
                >
                    <ArrowIcon class="icon--inline product-management-page__arrow-icon" />
                </Button>
            </Card>
        </div>
    </LayoutPage>
</template>

<script>
import Button from '@/components/Button/Button';
import LayoutPage from '@/components/Layout/Page.v2';
import Card from '@/components/Layout/Card';
import NavigationIcon from '@/components/Layout/Navigation/NavigationIcon';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';

export default {
    name: 'ProductManagementPage',
    components: {
        Button,
        LayoutPage,
        Card,
        NavigationIcon,
        Words,

        ArrowIcon,
    },
    data() {
        return {
            currentStage: this.$route.name,
            productManagementItems: [
                {
                    name: 'aggregate',
                    route: 'product-list',
                    icon: 'baustoffe',
                },
                {
                    name: 'waste',
                    route: 'waste-product',
                    icon: 'abfaelle',
                },
            ],
        };
    },
    computed: {
        childRoutes() {
            return [
                'management__product-management__product-list',
                'management__product-management__product-edit',
                'management__product-management__waste-product',
                'management__product-management__attribute-management',
            ];
        },
    },
    methods: {
        openRoute(route, pageTitle) {
            this.$router
                .push({
                    name: this.$root.findRouteName(route),
                    params: { pageTitle: this.$t(`pages.productManagement.tiles.titles.${pageTitle}`) },
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.product-management-page {
    background-color: $color-lightMediumGrey;

    .product-management-page__container {
        margin: 20px auto 0 auto;
        max-width: $layout-desktop-min;

        @media screen and (max-width: $layout-desktop-max) {
            margin: 15px;
            grid-template-columns: 1fr;
        }
    }
    .product-management-page__card {
        display: flex;
        max-width: 320px;

        .card__content {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .product-management-page__card-title-container {
        display: flex;
        align-items: center;
    }

    .product-management-page__card-icon {
        width: 48px;

        svg {
            width: 32px;
        }
    }

    .product-management-page__description {
        flex: 1;
        margin-left: 48px;
        margin-top: 15px;
        hyphens: auto;
    }

    .product-management-page__button {
        display: block;
        margin-left: auto;
        margin-top: 20px;
    }

    .product-management-page__arrow-icon {
        transform: rotate(180deg);
    }
}
</style>
