export const SettingsFields = [
    'costcenterMandatory',
    'isActive',
    'isBlocked',
    'salesStaff.id',
    'accountManager.id',
    'keyAccount',
    'types',
    'billingAddress.state',
    'carrierNumber',
];
