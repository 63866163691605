import { stateIdentifiers } from '@/services/Disposal/StateIdentifiers';
import { TextField } from '@schuettflix/react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
export function CarrierNumber({ state, value, onChange, ...rest }) {
    const { t } = useTranslation();
    const prefix = stateIdentifiers.find(id => id.shortName === state)?.wasteStateLetter.toUpperCase();
    const suffix = value?.slice(1) || '';
    useEffect(() => {
        if (onChange && prefix) {
            const value = (prefix + suffix).trim();
            if (value.length > 1) {
                onChange({ target: { value } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to react on state changes
    }, [state]);
    return (<div className="flex w-full gap-1">
            <TextField disabled value={prefix} className="w-12"/>
            <TextField {...rest} disabled={!prefix} className="w-[12rem]" label={t('disposal.carrierNumber.title')} value={suffix} onChange={e => {
            if (!prefix)
                return;
            const cleaned = e.target.value.toUpperCase().slice(0, 8);
            const full = prefix + cleaned;
            if (onChange && full !== value) {
                // @ts-expect-error overwriting the original event is bit sus. I'm sorry
                e.target.value = cleaned.length ? full : null;
                onChange(e);
            }
        }}/>
        </div>);
}
