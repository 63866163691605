<template>
    <LayoutPage
        is-flyout
        screen-name="platform-constructionproject-positioncreate"
        class="checkout-project-position-type-selection-page"
    >
        <template #flyoutHeader>
            <LayoutHeader />
        </template>

        <div v-if="project" class="container-off-canvas-sm my-6">
            <p class="font-copy-md mb-6">
                {{ $t('pages.checkout.projectPositionTypeSelection.headline') }}
            </p>
            <template v-for="(type, i) in typeOptions">
                <button
                    v-if="isEnabled(type)"
                    :key="i"
                    :class="'card card--hovered card--focussed mb-4 w-full text-left'"
                    :data-test="`project-position-${type.basketType}`"
                    @click.prevent="select(type)"
                >
                    <div class="item__container">
                        <component :is="type.icon" class="item__icon" />
                        <Words v-if="!type.isLoading" block bold>
                            {{ $t(`pages.checkout.projectPositionTypeSelection.${type.headline}`) }}
                        </Words>
                        <LoadingSpinner v-if="type.isLoading" dark />
                        <ArrowIcon class="item__arrow-icon" />
                    </div>
                </button>
            </template>
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ProjectApi from '@/services/Api/Project';
import ProjectFacadeView from '@/models/ProjectFacadeView';

import Card from '@/components/Layout/Card';
import LayoutHeader from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow-right-bold.svg';
import DeliveryIcon from '@/assets/icons/orderItems/delivery.svg';
import ShipmentOrderIcon from '@/assets/icons/orderItems/shipment-order.svg';
import DisposalHazardousIcon from '@/assets/icons/orderItems/disposalHazardous.svg';
import DisposalNonHazardousIcon from '@/assets/icons/orderItems/disposal.svg';
import Utils from '@/services/Api/Platform/Utils';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { useInAppBrowser } from '@/utils/composition-helper';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Toaster from '@/services/Toaster';
import { findRouteName } from '@/pages/routerUtils';
import { CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { getOldProjectIdByUuid } from '@/constructionProjects/api/projects';

export default {
    name: 'ProjectPositionTypeSelectionPage',
    components: {
        Card,
        LayoutHeader,
        LayoutPage,
        Words,

        ArrowIcon,
        DeliveryIcon,
        ShipmentOrderIcon,
        DisposalHazardousIcon,
        DisposalNonHazardousIcon,
        LoadingSpinner,
    },
    props: {
        projectId: {
            type: [Number, String],
            default: null,
        },
        id: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            project: null,
            typeOptions: [
                {
                    basketType: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY,
                    routeName: this.$root.findRouteName('checkout-project-delivery'),
                    icon: 'DeliveryIcon',
                    headline: 'delivery',
                    isLoading: false,
                },
                {
                    basketType: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT,
                    routeName: this.$root.findRouteName('checkout-project-shipment'),
                    icon: 'ShipmentOrderIcon',
                    headline: 'shipment',
                    isLoading: false,
                },
                {
                    basketType: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                    routeName: this.$root.findRouteName('checkout-project-disposal-hazardous'),
                    icon: 'DisposalHazardousIcon',
                    headline: 'disposalHazardous',
                    isLoading: false,
                },
                {
                    basketType: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
                    routeName: this.$root.findRouteName('checkout-project-disposal-non-hazardous'),
                    icon: 'DisposalNonHazardousIcon',
                    headline: 'disposalNonHazardous',
                    isLoading: false,
                },
                {
                    basketType: BASKET_TYPE_PROJECT.BASKET_TYPE_GENERIC_PROJECT_POSITION,
                    icon: 'DeliveryIcon',
                    headline: 'genericPosition',
                    isLoading: false,
                },
            ],
            isGlobalVariableDisposalFeatureActive: false,
        };
    },
    computed: {
        ...mapGetters('platform', ['genericOrderUrl']),
        ...mapState('basket', {
            typeFromBasket: 'type',
            constructionSiteFromBasket: 'constructionSite',
            projectIdFromBasket: 'projectId',
        }),
        ...mapState('projectPosition', ['requestCollection']),
    },
    async mounted() {
        const flags = await Utils.getFeatureFlags();
        this.isGlobalVariableDisposalFeatureActive = flags?.disposal_project_order;
    },
    async created() {
        let projectId = this.projectId;
        if (!projectId) {
            projectId = await getOldProjectIdByUuid(this.id);
        }
        const projectPayload = await ProjectApi.getOneById(projectId);
        this.project = ProjectFacadeView.create(projectPayload);
    },
    methods: {
        isEnabled(type) {
            if (['waste', 'dangerousWaste'].includes(type.basketType)) {
                return this.isGlobalVariableDisposalFeatureActive;
            }

            return true;
        },
        select(type) {
            if (type.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_GENERIC_PROJECT_POSITION) {
                this.typeOptions.find(
                    option => option.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_GENERIC_PROJECT_POSITION
                ).isLoading = true;

                ProjectApi.createGenericPosition(this.project.constructionProjectId)
                    .then(response => {
                        this.navigateToGenericProjectPositionCreation(response.genericPositionId);

                        this.$router
                            .push({
                                name: findRouteName(CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE),
                                params: this.$route.params,
                            })
                            .catch(err => {
                                this.$logger().error(err);
                            });
                    })
                    .catch(err => {
                        Toaster.error(err);
                    })
                    .finally(() => {
                        this.typeOptions.find(
                            option => option.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_GENERIC_PROJECT_POSITION
                        ).isLoading = false;
                    });

                return;
            }

            this.$store.commit('projectPosition/RESET');

            this.$store.dispatch('basket/init', {
                type: type.basketType,
                isCustom: true,
                project: this.project,
            });

            if (
                [
                    BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                    BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
                ].includes(type.basketType)
            ) {
                /**
                 * Tranfer data from basket store to project position store
                 * because this is the entry page for projectPosition hazardous and non-hazardouz waste
                 */
                this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                    requestCollection: { type: type.basketType },
                    property: 'type',
                });

                this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                    requestCollection: { project: { id: this.project.id } },
                    property: 'project',
                });

                // add preselected values based on basket type
                if (BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS === type.basketType) {
                    this.$store.commit('projectPosition/SET_BILLING_METHOD', 'weight');
                }
            }

            this.$router
                .push({
                    name: type.routeName,
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },
        navigateToGenericProjectPositionCreation(positionId) {
            const { openInAppBrowser } = useInAppBrowser();
            const genericProjectPositionUrl = `${this.genericOrderUrl}/position/${positionId}`;
            openInAppBrowser(genericProjectPositionUrl, `_self`);
        },
    },
};
</script>

<style lang="scss" scoped>
.item__container {
    display: grid;
    grid-template-columns: 65px auto 15px;
    align-items: center;
    gap: 10px;
}

.item__icon {
    height: 42px;
    max-width: 65px;
    width: 100%;
}

.item__arrow-icon {
    height: 14px;
}
</style>
