import * as LDClient from 'launchdarkly-js-client-sdk';
import { computed, reactive, unref } from 'vue';
import store from '@/store';
/**
 * Local override values for feature flags. Used to override LaunchDarkly feature flag
 * values for testing and development purposes.
 *
 * ⚠️ Make sure to **not commit** any overrides ⚠️
 */
const overrides = {
// 'monolith-display-generic-order-details-for-legacy-orders': false,
// 'monolith-display-generic-order-details-for-clients': true,
};
export let ld = null;
/**
 * Returns the current LaunchDarkly data (including `flags` and `client`)
 *
 * Should be used for non-reactive purposes (e.g. in a router guard)
 */
export function getLd() {
    return ld;
}
export function useLd(featureName) {
    if (ld === null) {
        throw Error('LaunchDarkly is not initialized');
    }
    if (featureName === undefined)
        return ld;
    return computed(() => {
        const flagValue = ld?.flags?.[unref(featureName)] || false;
        return overrides[unref(featureName)] ?? flagValue;
    });
}
export function isLdActivated(featureName) {
    if (ld === null) {
        throw Error('LaunchDarkly is not initialized');
    }
    if (featureName === undefined) {
        throw Error('FeatureName is not defined');
    }
    return overrides[featureName] ?? (ld?.flags?.[featureName] || false);
}
export function setupLaunchDarkly(bootstrap = {}) {
    if (ld) {
        return;
    }
    const user = (info) => {
        return info?.user
            ? {
                key: info.user.id.toString(),
                kind: 'user',
                firstName: info.user.firstName,
                lastName: info.user.lastName,
                appVersion: window.appVersion.number,
                appVersionNormalized: window.appVersion.normalized,
                organizationId: info.user.organization.id,
                organizationMarket: info.user.organization.market.code,
            }
            : {
                key: 'anonymous',
                kind: 'user',
                anonymous: true,
            };
    };
    const client = window.launchDarklyClientId
        ? LDClient.initialize(window.launchDarklyClientId, user(store.getters['user/info']))
        : new StubLDClient(bootstrap);
    ld = reactive({
        client: client,
        flags: bootstrap,
        ready: false,
        error: null,
    });
    client.on('ready', () => {
        if (ld) {
            ld.flags = client.allFlags();
            ld.ready = true;
        }
    });
    client.on('change', (changes) => {
        const currentFlags = Object.fromEntries(Object.entries(changes).map(([key, value]) => [key, value.current]));
        if (ld) {
            ld.flags = {
                ...ld.flags,
                ...currentFlags,
            };
        }
    });
    client.on('error', e => {
        if (ld) {
            ld.error = e;
        }
    });
    store.watch((_state, getters) => getters['user/info'], async (info) => {
        if (ld && info?.user) {
            await ld.client.identify(user(info));
        }
    });
    store.watch((_state, getters) => getters['user/isLoggedIn'], async (isLoggedIn) => {
        if (ld && !isLoggedIn) {
            await resetLaunchDarkly();
        }
    });
}
export async function resetLaunchDarkly() {
    if (!ld) {
        return;
    }
    await ld.client.close();
    ld = null;
}
/**
 * This is a stub implementation of the LaunchDarkly client. It is used when
 * LD is not enabled and in testing.
 */
export class StubLDClient {
    flags;
    onChange = [];
    constructor(flags) {
        this.flags = flags;
    }
    triggerChanges(newFlags) {
        const changeSet = new Map();
        for (const [key, value] of Object.entries(newFlags)) {
            changeSet.set(key, {
                current: value,
                previous: this.flags[key] ?? null,
            });
        }
        const changes = Object.fromEntries(changeSet.entries());
        this.onChange.forEach(callback => callback(changes));
    }
    // LDClient methods
    async identify() {
        return this.flags;
    }
    getContext() {
        return {};
    }
    variation(key, defaultValue) {
        return this.flags[key] ?? defaultValue;
    }
    variationDetail(key, defaultValue) {
        return {
            value: this.flags[key] ?? defaultValue,
            reason: undefined,
        };
    }
    on(key, callback) {
        switch (key) {
            case 'change':
                this.onChange.push(callback);
                break;
            case 'ready':
                // when passed a callback we call it immediately to indicate the
                // client is ready
                callback();
                break;
        }
    }
    allFlags() {
        return this.flags;
    }
    setStreaming() { }
    off() { }
    track() { }
    alias() { }
    async waitUntilGoalsReady() { }
    async waitUntilReady() { }
    async waitForInitialization() { }
    async flush() { }
    async close() { }
}
