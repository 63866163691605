import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@/constructionProjects/components/Header';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useProductSchemaList, } from '@/pages/ProductManagement/AdvanceManagement/hooks/useProductSchemaList';
import { Accordion, Button, Checkbox, Combobox, IconButton, MessageBox, Tag, TextField, } from '@schuettflix/react-components';
import { CloseIcon, PlusIcon } from '@schuettflix/icons-react';
import clsx from 'clsx';
import { snakeCase } from 'lodash';
import { useCreateAttributeValue } from '@/pages/ProductManagement/AdvanceManagement/hooks/useCreateAttributeValue';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateAttributeValue } from '@/pages/ProductManagement/AdvanceManagement/hooks/useUpdateAttributeValue';
import { useDeleteAttributeValue } from '@/pages/ProductManagement/AdvanceManagement/hooks/useDeleteAttributeValue';
import { useLaunchDarkly } from '@/reactBridge/useLaunchDarkly';
const marketCodes = ['DE', 'AT', 'PL', 'CZ'];
function Layout({ children }) {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<div className="flex h-full flex-col overflow-auto bg">
            <Header desktopTitle="Attribute Management" mobileTitle="Attribute Management" navigationTitle={t('pages.productManagement.tiles.titles.aggregate')} onBack={() => vueRouter.push({ name: 'management__product-management__product-list' })}/>

            <div className="p-6">{children}</div>
        </div>);
}
/**
 * Creates translation key with the following pattern:
 * product_catalog.attribute_value.{attributeSetName}.{targetName}.label
 */
function assembleAttributeValueTranslationKey(attributeSetKey, targetName) {
    const matches = attributeSetKey.match(/^product_catalog\.attribute_set\.(.+?)\.label$/);
    if (matches === null) {
        throw new Error('Invalid attribute set key provided');
    }
    const attributeSetName = matches[1];
    return `product_catalog.attribute_value.${attributeSetName}.${snakeCase(targetName)}.label`;
}
function EditAttributeSetConfigChoice({ attributeSet, configChoice, onClose }) {
    const { t } = useTranslation();
    const { mutateAsync: deleteAttributeValue, isLoading: isDeleteAttributeValueLoading } = useDeleteAttributeValue(configChoice.id);
    const { mutateAsync: updateAttributeValue, isLoading: isUpdateAttributeValueLoading } = useUpdateAttributeValue();
    const form = useForm({
        defaultValues: {
            marketCodes: configChoice.availableMarkets,
        },
    });
    const onSubmitHandler = form.handleSubmit(async (data) => {
        await updateAttributeValue({
            ...data,
            attributeValueId: configChoice.id,
        });
        onClose();
    });
    const handleDeleteAttributeValue = async () => {
        if (window.confirm('Are you sure you want to delete this attribute value?')) {
            await deleteAttributeValue();
            onClose();
        }
    };
    return (<form className="grid gap-4 bg-white p-4" onSubmit={onSubmitHandler}>
            <div className="flex justify-between">
                <div className="font-headline-lg-strong">
                    {t(attributeSet.label)}: {configChoice.label}
                </div>
                <IconButton icon={CloseIcon} size="md" onClick={onClose}/>
            </div>

            <div>{configChoice.translationKey ?? 'Not translatable'}</div>

            <Controller control={form.control} name="marketCodes" render={({ field }) => (<Combobox label="Markets" multiple options={marketCodes.map(marketCode => ({ label: marketCode, value: marketCode }))} value={field.value} onChange={field.onChange}/>)}/>

            <div className="flex flex-row-reverse gap-4">
                <Button label="Delete attribute" size="sm" variant="secondary" type="button" disabled={isUpdateAttributeValueLoading || isDeleteAttributeValueLoading} onClick={handleDeleteAttributeValue}/>
                <Button label="Update attribute" size="sm" disabled={isUpdateAttributeValueLoading || isDeleteAttributeValueLoading}/>
            </div>
        </form>);
}
function CreateAttributeSetConfigChoice({ attributeSet, onClose }) {
    const { t } = useTranslation();
    const { mutateAsync, isLoading } = useCreateAttributeValue();
    const form = useForm({
        defaultValues: {
            isTranslatable: false,
            isCertifiable: false,
            attributeValueName: '',
            marketCodes: [],
        },
    });
    const onSubmitHandler = form.handleSubmit(async (data) => {
        const options = {};
        if (data.isCertifiable) {
            options['isCertifiable'] = true;
        }
        await mutateAsync({
            ...data,
            attributeSetId: attributeSet.id,
            options,
        });
        onClose();
    });
    const targetName = form.watch('attributeValueName');
    const isTranslatable = form.watch('isTranslatable');
    const previewLabel = isTranslatable
        ? assembleAttributeValueTranslationKey(attributeSet.key, targetName)
        : targetName;
    if (attributeSet.config.type !== 'CHOICE') {
        return <div>{attributeSet.config.type} is currently not supported</div>;
    }
    const similarChoice = attributeSet.config.choices.find(choice => (choice.translationKey ?? choice.label) === previewLabel);
    const isValid = similarChoice === undefined && targetName !== '';
    return (<form className="grid gap-4 bg-white p-4" onSubmit={onSubmitHandler}>
            <div className="flex justify-between">
                <div className="font-headline-lg-strong">{t(attributeSet.label)}</div>
                <IconButton icon={CloseIcon} size="md" onClick={onClose}/>
            </div>

            {similarChoice && (<MessageBox type="warning" title="Already exists">
                    <div>{similarChoice.label}</div>
                    <div>{similarChoice.translationKey ?? 'Not translated'}</div>
                </MessageBox>)}

            <div className="grid gap-2">
                <div className="font-headline-md-strong">Label Preview:</div>
                <div style={{ fontFamily: 'monospace' }} className="bg-surface-subdued/30 p-2 text-sm">
                    {previewLabel || '<empty>'}
                </div>

                <TextField label="Name" {...form.register('attributeValueName')}/>
            </div>

            <div className="flex gap-2">
                <Controller control={form.control} name="isTranslatable" render={({ field }) => (<Checkbox checked={field.value} onCheckedChange={checked => field.onChange(checked === true)}/>)}/>
                is translatable
            </div>
            <div className="flex gap-2">
                <Controller control={form.control} name="isCertifiable" render={({ field }) => (<Checkbox checked={field.value} onCheckedChange={checked => field.onChange(checked === true)}/>)}/>
                is certifiable
            </div>

            <div>
                <Controller control={form.control} name="marketCodes" render={({ field }) => (<Combobox label="Markets" multiple options={marketCodes.map(marketCode => ({ label: marketCode, value: marketCode }))} value={field.value} onChange={field.onChange}/>)}/>
            </div>

            <div>
                <Button type="submit" size="sm" label="Create attribute" disabled={!isValid || isLoading}/>
            </div>
        </form>);
}
function AttributeSetDisplay({ attributeSet, onSelectConfigChoice }) {
    const configType = attributeSet.config.type;
    if (configType === 'CHOICE') {
        return (<div className="flex flex-wrap items-start gap-2">
                {attributeSet.config.choices.map(choice => {
                return (<Tag size="sm" subdued label={choice.label} key={choice.id} title={choice.translationKey ?? 'Not translatable'} type={choice.availableMarkets.length === 0 ? 'warning' : 'default'} className={clsx({
                        'cursor-pointer hover:bg-black hover:text-white': !!onSelectConfigChoice,
                    })} onClick={() => onSelectConfigChoice && onSelectConfigChoice(choice)}/>);
            })}
            </div>);
    }
    if (configType === 'COLOR') {
        return (<div className="flex flex-wrap items-start gap-2">
                {attributeSet.config.choices.map(choice => (<Tag size="sm" subdued label={choice.label} key={choice.id} title={choice.color} style={{ backgroundColor: choice.color }}/>))}
            </div>);
    }
    return <div>{configType} is unsupported</div>;
}
function ProductSchemaList({ productSchemaList, selectedAttributeSet, onEditAttributeSet, onSelectConfigChoice, }) {
    const { t } = useTranslation();
    return (<Accordion>
            {productSchemaList.map(productSchema => (<Accordion.Item key={productSchema.id} title={t(productSchema.label)}>
                    <div className="-m-4 grid gap-12 bg-white p-4">
                        {productSchema.attributeSets.map(attributeSet => (<div key={attributeSet.id} className={clsx('grid gap-4', {
                    'opacity-20': selectedAttributeSet !== undefined &&
                        selectedAttributeSet?.id !== attributeSet.id,
                })}>
                                <div className="flex justify-between gap-2">
                                    <div className="font-headline-lg-strong">{t(attributeSet.label)}</div>
                                    {attributeSet.config.type !== 'RANGE' && (<IconButton icon={PlusIcon} size="sm" onClick={() => onEditAttributeSet(attributeSet)}/>)}
                                </div>
                                <AttributeSetDisplay attributeSet={attributeSet} onSelectConfigChoice={choice => onSelectConfigChoice(attributeSet, choice)}/>
                            </div>))}
                    </div>
                </Accordion.Item>))}
        </Accordion>);
}
const _AttributeManagementPage = () => {
    const [selection, setSelection] = useState();
    const { data } = useProductSchemaList();
    const isAdvancedMasterProductCatalogManagementEnabled = useLaunchDarkly('advanced-master-product-catalog-management');
    if (!isAdvancedMasterProductCatalogManagementEnabled) {
        return <Layout>Access denied</Layout>;
    }
    if (!data) {
        return <Layout>Loading ....</Layout>;
    }
    return (<Layout>
            <div className="grid grid-cols-2 items-start gap-6">
                <ProductSchemaList productSchemaList={data} selectedAttributeSet={selection?.attributeSet} onEditAttributeSet={attributeSet => setSelection({
            attributeSet,
        })} onSelectConfigChoice={(attributeSet, configChoice) => setSelection({
            attributeSet,
            configChoice,
        })}/>
                {selection && (<div className="sticky top-6">
                        {selection.configChoice ? (<EditAttributeSetConfigChoice key={`${selection.attributeSet.id}-${selection.configChoice.id}`} attributeSet={selection.attributeSet} configChoice={selection.configChoice} onClose={() => setSelection(undefined)}/>) : (<CreateAttributeSetConfigChoice key={selection.attributeSet.id} attributeSet={selection.attributeSet} onClose={() => setSelection(undefined)}/>)}
                    </div>)}
            </div>
        </Layout>);
};
export const AttributeManagementPage = asVueComponent(_AttributeManagementPage);
