<template>
    <LayoutPage
        class="organization-form"
        :screen-name="isPlatformAdministrator ? 'platform-organization-detail' : 'organization-detail'"
        :is-flyout="!isPage"
        :class="{ 'organization-form--flyout': !isPage }"
        :parent-route="{ name: $root.findRouteName(parentRoute), query: $route.query }"
        @close="handleClose()"
    >
        <template #pageTitle>
            <div v-if="$route.params.pageTitle">
                {{ $route.params.pageTitle }}
            </div>
            <div v-else>
                {{ organization && organization.name }}
            </div>
        </template>

        <LoadingSpinner v-if="isPending('load')" block dark />

        <InfoMessage
            v-else-if="errorCode"
            transparent
            :title="$t(`pages.organization.error.${errorCode}.headline`)"
            :text="$t(`pages.organization.error.${errorCode}.msg`)"
            :cta="$t('pages.organization.error.cta')"
            @cta-click="$router.push({ name: 'management__organization-list' })"
        >
            <template #icon>
                <SfOrganizationsIcon />
            </template>
        </InfoMessage>

        <div v-else :class="{ 'container-deprecated': $root.isDesktop }" class="organization-form__container-forms">
            <OrganizationFormVue
                v-if="organization"
                :defaultValues="organization"
                :onSubmit="onSubmit"
                :readonly="readonly"
                :saveOnSection="true"
            />
        </div>
    </LayoutPage>
</template>

<script>
import { EVENT_ORGANIZATION_DELETED } from '@/constants/events';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import pageMixin from '@/plugins/mixins/pageMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import OrganizationApi from '@/services/Api/Organization';
import validate from '@/services/validation/mixin';

import LayoutPage from '@/components/Layout/Page.v2.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import InfoMessage from '@/pages/Home/components/InfoMessage.vue';
import { OrganizationFormVue } from '@/pages/Magic/forms/organization/OrganizationForm';
import { SfOrganizationsIcon } from '@schuettflix/vue-components';
import { mapGetters } from 'vuex';

export default {
    name: 'OrganizationFormPage',
    components: {
        OrganizationFormVue,
        InfoMessage,
        SfOrganizationsIcon,
        LayoutPage,
        LoadingSpinner,
    },
    mixins: [validate, statefulMixin, eventHubMixin, pageMixin],
    props: {
        organizationId: {
            type: [Number, String],
            default: null,
        },
        parentRoute: {
            type: String,
            default: null,
        },
    },
    setup() {},
    data() {
        return {
            organization: null,
        };
    },

    computed: {
        readonly() {
            return (
                this.$can('viewOrganization', this.organization) && !this.$can('updateOrganization', this.organization)
            );
        },
        ...mapGetters('user', {
            userOrganizationId: 'organizationId',
        }),
    },

    async created() {
        await this.refreshOrganization();

        this.subscribe(EVENT_ORGANIZATION_DELETED, organization => {
            if (organization && this.organization && organization.id === parseInt(this.organization.id)) {
                this.$root.routeBack();
            }
        });
    },

    methods: {
        async onSubmit(data) {
            const result = await OrganizationApi.save(data);
            this.$emit('updated', result);
        },

        currentOrganizationId() {
            return this.organizationId === null ? this.userOrganizationId : this.organizationId;
        },

        async refreshOrganization() {
            await this.statefulRun('load', async () => {
                try {
                    const result = await OrganizationApi.getOneById(this.currentOrganizationId());
                    this.organization = result;
                } catch (err) {
                    this.errorCode = err.code;
                }
            });
        },

        handleClose() {
            this.$parent.$emit('close');
            this.$root.routeBack();
        },
    },
};
</script>
