import { z } from 'zod';
const POSITION_STATUS_SCHEMA = z.enum(['DRAFT', 'OPEN', 'CLOSED']);
const PRODUCT_TYPE_SCHEMA = z.enum([
    'CUSTOM_BULK_MATERIAL',
    'CUSTOM_RECYCLED_BULK_MATERIAL',
    'CUSTOM_TRANSPORT',
    'CUSTOM_CONCRETE',
    'CUSTOM_ASPHALT',
    'CUSTOM_SERVICE',
    'CUSTOM_SERVICE_ANALYSIS',
    'CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT',
    'CUSTOM_SERVICE_TOLL',
    'CUSTOM_SERVICE_WAITING_TIME',
    'CUSTOM_SERVICE_TRANSPORT',
    'BULK_MATERIAL',
    'TRANSPORT',
    'CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL',
    'CUSTOM_HAZARDOUS_WASTE_DISPOSAL',
]);
export const PRODUCT_CATEGORY_SCHEMA = z.enum([
    'MATERIAL',
    'TRANSPORT',
    'MATERIAL_TRANSPORT',
    'MATERIAL_SERVICE',
    'TRANSPORT_SERVICE',
]);
export const PRODUCT_GROUP_TYPE_SCHEMA = z.enum(['FRANCO', 'MERCHANT', 'STANDARD']);
export const SUPPORTED_UNITS_SCHEMA = z.enum([
    'DAY',
    'HOUR',
    'KILOMETER',
    'KILOGRAM',
    'TON',
    'CUBIC_METER',
    'CUBIC_CENTIMETER',
    'LITER',
    'PIECE',
    'TRANSPORT',
    'FLAT_RATE',
    'MINUTE',
]);
const CustomerInfoSchema = z.strictObject({
    id: z.string().uuid(),
    positionId: z.string().uuid(),
    constructionProjectId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
export const PositionProductSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    unit: SUPPORTED_UNITS_SCHEMA,
    quota: z.number(),
    availableQuantity: z.number(),
    reservedQuantity: z.number().nonnegative(),
    fulfilledQuantity: z.number().nonnegative(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    name: z.string(),
});
const PositionProductGroupSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    products: z.array(PositionProductSchema),
});
export const GetPositionResponseSchema = z.object({
    id: z.string().uuid(),
    projectNumber: z.string(),
    positionNumber: z.string(),
    marketCode: z.string(),
    validFrom: z.coerce.date().nullable(),
    validTo: z.coerce.date().nullable(),
    note: z.string().nullable(),
    status: POSITION_STATUS_SCHEMA,
    active: z.boolean(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    orderCount: z.number(),
    customerInfo: CustomerInfoSchema,
    positionProductGroups: z.array(PositionProductGroupSchema),
    allowManualFulfillment: z.boolean(),
});
export const ListGenericPositionItemSchema = z.object({
    ...GetPositionResponseSchema.shape,
});
export const ListGenericPositionsResponseSchema = z.object({
    positions: z.array(ListGenericPositionItemSchema),
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
});
export const GetPositionProductGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
});
export const ORDER_ORGANIZATION_ROLE_SCHEMA = z.enum(['CUSTOMER', 'SUPPLIER', 'CARRIER', 'CUSTOM']);
export const TOTAL_TYPE_SCHEMA = z.enum(['ORDER', 'FULFILLMENT']);
export const ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA = z.enum(['DEBTOR', 'CREDITOR']);
export const TotalSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    totalPriceAmount: z.number(),
    totalPriceCurrency: z.string(),
});
export const TransactionalTotalsSchema = z.strictObject({
    totalType: TOTAL_TYPE_SCHEMA,
    transactionalTotals: z.array(TotalSchema),
});
export const TransactionSchema = z.object({
    productId: z.string().uuid(),
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    quantity: z.number(),
    unit: z.string(),
    unitTaxClassId: z.string().optional(),
    unitPriceAmount: z.number(),
    unitPriceCurrency: z.string().length(3),
    productName: z.string(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    groupType: PRODUCT_GROUP_TYPE_SCHEMA.optional(),
});
export const TransactionWithTaxClassSchema = TransactionSchema.merge(z.object({ unitTaxClass: z.object({ name: z.string(), rate: z.number() }).optional() }));
export const BILLING_TYPE_SCHEMA = z.enum(['INVOICE', 'PARTNER_INVOICE', 'CREDIT_NOTE']);
export const BILLING_INTERVAL_SCHEMA = z.enum([
    'on_order_closed',
    'daily',
    'monthly',
    'weekly',
    'bi_weekly',
    'manually',
]);
export const PaymentSettingSchema = z.strictObject({
    billingMethod: BILLING_TYPE_SCHEMA,
    paymentTermId: z.number().nullable(),
    billingInterval: BILLING_INTERVAL_SCHEMA,
});
export const PaymentSettingsWithTransactionalRoleResponseSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    ...PaymentSettingSchema.shape,
});
export const PositionOverviewFinanceSettingSchema = PaymentSettingsWithTransactionalRoleResponseSchema.omit({
    paymentTermId: true,
}).merge(z.strictObject({
    paymentTerm: z
        .strictObject({
        id: z.number(),
        localizedDescription: z.string(),
    })
        .nullable(),
}));
export const PositionOverviewOrganizationSchema = z.strictObject({
    orgId: z.number(),
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    financeSettings: z.array(PositionOverviewFinanceSettingSchema),
    totals: z.array(TransactionalTotalsSchema),
    transactions: z.array(TransactionWithTaxClassSchema),
});
export const GetPositionOverviewResponseSchema = z.strictObject({
    organizations: z.array(PositionOverviewOrganizationSchema),
});
// Note: This schema does not contain all fields. Feel free to extend whenever needed.
const ParticipatingOrganizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    billingAddress: z.object({
        city: z.string(),
        country: z.string(),
        number: z.string(),
        state: z.string(),
        street: z.string(),
        zip: z.string(),
    }),
    productCategories: z.array(PRODUCT_CATEGORY_SCHEMA),
});
export const GetPositionDetailsResponseSchema = z.strictObject({
    position: GetPositionResponseSchema,
    organizations: z.array(ParticipatingOrganizationSchema),
});
export const SUPPORTED_UNITS_LABEL = {
    DAY: 'day',
    HOUR: 'hour',
    KILOMETER: 'kilometer',
    KILOGRAM: 'kilogram',
    TON: 'ton',
    CUBIC_METER: 'cbm',
    CUBIC_CENTIMETER: 'cubicCentimeter',
    LITER: 'liter',
    PIECE: 'piece',
    TRANSPORT: 'transport',
    FLAT_RATE: 'flatRate',
    MINUTE: 'minute',
};
