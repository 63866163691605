const context = {
    documentUseSelfBilling: false,
};
export function updateContext(newContext) {
    if (context.documentUseSelfBilling === newContext.documentUseSelfBilling)
        return;
    context.documentUseSelfBilling = newContext.documentUseSelfBilling;
}
export function selectCreditNoteDocKey({ creditNote, selfBilling }) {
    return makeSelectCreditNoteDocKey(context)({
        creditNote,
        selfBilling,
    });
}
export function makeSelectCreditNoteDocKey(context) {
    return ({ creditNote, selfBilling }) => {
        return context?.documentUseSelfBilling ? selfBilling : creditNote;
    };
}
