import { CarrierNumber } from '@/pages/Magic/components/CarrierNumber';
import { PhoneSelector } from '@/pages/Magic/components/PhoneSelector';
import { Button, Combobox, TextField } from '@schuettflix/react-components';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldError } from '../../../components/FieldError';
import { ImageEdit } from '../../../components/ImageEdit';
import { useBillingAddressSettings } from '../../../hooks/api';
import { useSubForm } from '../form';
import { useIsMarketCode, useIsOrgType } from '../hook';
import { BaseInformationFields } from './common';
export function BaseInformationEdit(props) {
    const { t } = useTranslation();
    const { register, control, formState: { errors }, handleSubmit, watch, setValue, trigger, } = useSubForm(BaseInformationFields, {
        defaultValues: props.defaultValue,
        mode: 'all',
    });
    const country = watch('billingAddress.country');
    const billingAddressSettings = useBillingAddressSettings();
    const phone = watch('phone');
    const mobile = watch('mobile');
    const fax = watch('fax');
    const state = watch('billingAddress.state');
    const types = watch('types');
    const isGermanMarket = useIsMarketCode('DE');
    const isType = useIsOrgType(types);
    useEffect(() => {
        trigger();
    }, [trigger]);
    return (<form onSubmit={handleSubmit(props.onSave)}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col">
                <p className="font-copy-lg pb-8">{t('pages.organization.form.baseInfo.headline')}</p>
                <TextField {...register('name')} label={t('components.organizationForm.name')}/>
                <FieldError error={errors.name}/>
                <div className=" flex flex-col pt-6">
                    <p className="font-copy-md-strong pb-2">{t('components.organizationForm.billingAddress.title')}</p>
                    <div className="flex flex-col gap-4">
                        <TextField {...register('billingAddress.additionalAddress')} label={t('components.organizationForm.billingAddress.additionalAddress')}/>
                        <FieldError error={errors.billingAddress?.additionalAddress}/>

                        <div className="flex gap-4">
                            <div className="flex-1">
                                <TextField {...register('billingAddress.street')} label={t('components.organizationForm.billingAddress.street')}/>
                                <FieldError error={errors.billingAddress?.street}/>
                            </div>
                            <div className="w-1/4">
                                <TextField {...register('billingAddress.number')} label={t('components.organizationForm.billingAddress.number')}/>
                                <FieldError error={errors.billingAddress?.number}/>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-1/4">
                                <TextField {...register('billingAddress.zip')} label={t('components.organizationForm.billingAddress.zip')}/>
                                <FieldError error={errors.billingAddress?.zip}/>
                            </div>
                            <div className="flex-1">
                                <TextField {...register('billingAddress.city')} label={t('components.organizationForm.billingAddress.city')}/>
                                <FieldError error={errors.billingAddress?.city}/>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="flex-1">
                                <Controller control={control} name="billingAddress.country" render={({ field }) => (<Combobox {...field} value={field.value || null} options={billingAddressSettings.data?.countries.map(c => ({
                value: c.code,
                label: t(c.name),
            })) ?? []} label={t('components.organizationForm.billingAddress.country')}/>)}/>
                                <FieldError error={errors.billingAddress?.country}/>
                            </div>
                            <div className="flex-1">
                                <Controller control={control} name="billingAddress.state" render={({ field }) => (<Combobox {...field} value={field.value || null} options={billingAddressSettings.data?.countries
                .find(c => c.code === country)
                ?.states.map(s => ({ value: s.code, label: t(s.name) })) ?? []} label={t('components.organizationForm.billingAddress.state')} placeholder={t('components.magic.organizationForm.billingAddress.placeholder')}/>)}/>
                                <FieldError error={errors.billingAddress?.state}/>
                            </div>
                        </div>
                    </div>

                    <p className="font-copy-md-strong pb-2 pt-6">
                        {t('components.organizationForm.billingInfo.title')}
                    </p>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-4">
                            <div className="flex-1">
                                <TextField {...register('taxId')} label={t('components.organizationForm.tax.taxId')}/>
                                <FieldError error={errors.taxId}/>
                            </div>
                            <div className="flex-1">
                                <TextField {...register('vatId')} label={t('components.organizationForm.tax.vatId')}/>
                                <FieldError error={errors.vatId}/>
                            </div>
                        </div>
                    </div>

                    {isGermanMarket && isType.carrier && (<>
                            <p className="font-copy-md-strong pb-2 pt-6">{t('disposal.carrierNumber.title')}</p>
                            <div>
                                <Controller control={control} name="carrierNumber" render={({ field }) => (<CarrierNumber {...field} state={state} label={t('disposal.carrierNumber.title')}/>)}/>
                                <FieldError error={errors.carrierNumber}/>
                            </div>
                        </>)}

                    <p className="font-copy-md-strong pb-2 pt-6">
                        {t('components.organizationForm.contactInfo.title')}
                    </p>
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <PhoneSelector value={phone} onChange={value => setValue('phone', value)}/>
                                {/* The error is in the DeepPick function in partial.ts */}
                                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                {/* @ts-ignore */}
                                <FieldError error={errors.phone?.number}/>
                            </div>
                            <div>
                                <PhoneSelector value={mobile} onChange={value => setValue('mobile', value)}/>
                                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                {/* @ts-ignore */}
                                <FieldError error={errors.mobile?.number}/>
                            </div>
                            <div>
                                <PhoneSelector value={fax} onChange={value => setValue('fax', value)}/>
                                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                {/* @ts-ignore */}
                                <FieldError error={errors.fax?.number}/>
                            </div>
                        </div>

                        <div>
                            <TextField {...register('email')} label={t('components.organizationForm.contact.email')}/>
                            <FieldError error={errors.email}/>
                        </div>
                        <div>
                            <TextField {...register('web')} label={t('components.organizationForm.contact.website')}/>
                            <FieldError error={errors.web}/>
                        </div>
                    </div>
                    <p className="font-copy-md-strong pb-2 pt-6">{t('components.organizationForm.addLogo.title')}</p>
                    <Controller control={control} name="logo" render={({ field }) => (<ImageEdit value={field.value} onChange={logo => field.onChange(logo)}/>)}/>

                    <div className="flex justify-center gap-4 py-16">
                        <Button type="reset" onClick={props.onCancel} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                        <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                    </div>
                    <hr className="border-divider pb-16"/>
                </div>
            </div>
        </form>);
}
