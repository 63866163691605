import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { LoadingSpinner } from '@schuettflix/react-components';
import { useCreateGenericOrderByPositionAndForward } from '@/modules/constructionProjects/hooks/useCreateGenericOrderByGenericPositionAndForward';
import { GenericPositionAdminActions } from './GenericPositionAdminActions';
import { useActivateGenericPositionById } from '../hooks/useActivateGenericPositionById';
import { asyncDelay } from '@/services/utils';
import { invalidateGetGenericPositionDetailsByIdQuery } from '@/modules/constructionProjects/api/shared/getGenericPositionDetailsById';
import Toaster from '@/services/Toaster';
import { DetailsSection, GenericPositionDetails, } from '../../../shared/ConstructionProjectGenericPositionDetailsPage/ConstructionProjectGenericPositionDetails';
import { GenericPositionPrices } from '../../../shared/ConstructionProjectGenericPositionDetailsPage/ConstructionProjectGenericPositionPrices';
import { PositionOverviewContent } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/PositionOverviewContent';
import { useDirectFulfillment } from '../hooks/useDirectFulfillment';
function DetailsAdminSection({ position }) {
    const { t } = useTranslation();
    const [openInactivePositionModal, setOpenInactivePositionModal] = useState(false);
    const activateGenericPosition = useActivateGenericPositionById(position.id);
    const queryClient = useQueryClient();
    const { openDirectFulfillmentPage, isDirectFulfillmentAllowed } = useDirectFulfillment(position);
    const createGenericOrder = useCreateGenericOrderByPositionAndForward(position.id);
    // Confirm closing shows a loading indicator optimistically (2 secs or so) for monolith-service-sync reasons
    const [fakeLoading, setFakeLoading] = useState(false);
    return (<DetailsSection position={position} pricesSlot={<GenericPositionPrices>
                    <PositionOverviewContent positionId={position.id} positionProductGroupType={position.positionProductGroups[0].type}/>
                </GenericPositionPrices>} buttonBarSlot={<>
                    {position.status === 'OPEN' && (<div className="flex justify-end gap-6">
                            {isDirectFulfillmentAllowed && (<Button prominence="secondary" onPress={() => {
                        if (!position.active) {
                            setOpenInactivePositionModal(true);
                            return;
                        }
                        openDirectFulfillmentPage();
                    }}>
                                    {t('pages.genericPosition.details.startDirectFulfillment')}
                                </Button>)}
                            <Button prominence="primary" leadingSlot={createGenericOrder.isLoading ? <LoadingSpinner /> : undefined} onPress={() => {
                    if (position.active) {
                        return createGenericOrder.mutateAsync();
                    }
                    setOpenInactivePositionModal(true);
                }} isDisabled={createGenericOrder.isLoading}>
                                {t('pages.genericPosition.details.startOrder')}
                            </Button>
                            <ModalTrigger isOpen={openInactivePositionModal} onOpenChange={() => setOpenInactivePositionModal(!openInactivePositionModal)}>
                                <Modal>
                                    {({ close }) => (<>
                                            <Modal.Heading onAbort={close}>
                                                {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.title')}
                                            </Modal.Heading>
                                            <Modal.Content>
                                                {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.content')}
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button onPress={close} prominence="secondary">
                                                    {t('pages.genericPosition.details.orderingOnInactiveGenericPositionModal.cancel')}
                                                </Button>
                                                <Button prominence="primary" leadingSlot={(activateGenericPosition.isLoading || fakeLoading) && (<LoadingSpinner />)} isDisabled={activateGenericPosition.isLoading || fakeLoading} onPress={async () => {
                        setFakeLoading(true);
                        try {
                            await Promise.all([
                                activateGenericPosition.mutateAsync(),
                                await asyncDelay(1000),
                            ]);
                            invalidateGetGenericPositionDetailsByIdQuery(queryClient, position.id);
                            close();
                            Toaster.success(t('pages.genericPosition.details.activateGenericPosition.success'));
                        }
                        catch (error) {
                            close();
                            Toaster.error(t('pages.genericPosition.details.activateGenericPosition.error'));
                        }
                        finally {
                            setFakeLoading(false);
                        }
                    }}>
                                                    {t('pages.genericPosition.details.contextMenu.activateGenericPosition')}
                                                </Button>
                                            </Modal.Actions>
                                        </>)}
                                </Modal>
                            </ModalTrigger>
                        </div>)}
                </>}/>);
}
export const GenericPositionAdminDetails = ({ className }) => {
    return (<GenericPositionDetails className={className} actionsSlot={position => <GenericPositionAdminActions genericPositionId={position.id}/>} detailsSlot={position => <DetailsAdminSection position={position}/>}/>);
};
