export const BaseInformationFields = [
    'name',
    'billingAddress.street',
    'billingAddress.country',
    'billingAddress.number',
    'billingAddress.zip',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.additionalAddress',
    'taxId',
    'vatId',
    'phone.countryCode',
    'phone.number',
    'mobile.countryCode',
    'mobile.number',
    'fax.countryCode',
    'fax.number',
    'email',
    'web',
    'logo.uuid',
    'carrierNumber',
    'types',
];
