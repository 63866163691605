import { Button, LoadingSpinner, MessageBox, ToggleSwitch } from '@schuettflix/react-components';
import { useState } from 'react';
import { useDefinitions } from '../Settings/definition';
import { useTranslation } from 'react-i18next';
export function SelectTypes(props) {
    const { t } = useTranslation();
    const definitions = useDefinitions();
    const [types, setTypes] = useState([]);
    if (definitions.isLoading || !definitions.data) {
        return <LoadingSpinner />;
    }
    if (definitions.isError) {
        return <p>Could not load definitions</p>;
    }
    return (<div className="flex flex-col gap-8">
            <MessageBox type="warning">{t('components.organization.warningMessage.selectType')}</MessageBox>
            <div className="flex flex-col gap-8">
                {Object.entries(definitions.data.organizationTypes).map(([key, value]) => (<div key={key}>
                        <ToggleSwitch checked={types.includes(key)} onChange={active => {
                if (active) {
                    setTypes([...types, key]);
                }
                else {
                    setTypes(types.filter(t => t !== key));
                }
            }} label={value.title} size="sm"/>
                        <div className="font-copy-lg">{value.headline}</div>
                        <p>{value.description}</p>
                    </div>))}
            </div>
            <div className="flex justify-end">
                <Button label={t('components.organizationForm.save')} size="sm" disabled={types.length === 0} onClick={() => props.onChange([...types])}/>
            </div>
        </div>);
}
