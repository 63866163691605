import useIsPlatformAdmin from '@/hooks/useIsPlatformAdmin';
import { platformInfoQuery } from '@/reactBridge/queryClient';
import { useVuexGetter } from '@/reactBridge/useVuex';
import store from '@/store';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useOrganizationById, useOrganizationBySalesForceId } from '../../hooks/api';
export function useIsOrgType(types) {
    const client = types.includes('client');
    const carrier = types.includes('carrier');
    const supplier = types.includes('supplier');
    const platform = types.includes('platform');
    return { client, carrier, supplier, platform };
}
export function useCheckExistingOrg(id, salesForceId) {
    const isPlatformAdmin = useIsPlatformAdmin();
    const orgById = useOrganizationById(id);
    const orgBySalesForceId = useOrganizationBySalesForceId(isPlatformAdmin ? salesForceId : undefined);
    /**
     * If salesForce id and org id point to different organizations, this should cause a warning/error
     */
    const idMismatch = orgById.data && orgBySalesForceId.data && orgById.data.id !== orgBySalesForceId.data.id;
    const isNew = orgById.data === null && orgBySalesForceId.data === null;
    const org = orgById.data ?? orgBySalesForceId.data ?? null;
    const isLoading = orgById.isLoading || orgBySalesForceId.isLoading;
    return { isNew, idMismatch, org, isLoading };
}
export function useIsMarketCode(code) {
    const platformInfo = useQuery(platformInfoQuery);
    return platformInfo.data?.platform.market.code === code.toUpperCase();
}
export function useIsPlatformUser() {
    return useVuexGetter(store, useCallback(getters => getters['user/organizationTypes'], [])).includes('platform');
}
