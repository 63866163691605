// Main purpose of these states is framework agnostic communication
// between the monolith and SFLX Apps.
//
// DO USE them when you need to pass on reactive state to other SFLX Apps
//
// DO NOT USE them when managing state inside the monolith and
// vuex would be a perfect fit
import SimpleState from '@schuettflix/util-simple-state';
export const baseUrlMonolithFrontend = new SimpleState(location.origin);
export const accessToken = new SimpleState('');
export const baseUrl = new SimpleState('');
export const baseUrlConstructionProject = new SimpleState('');
export const gatewayUrl = new SimpleState('');
export const userLocale = new SimpleState('');
export const environment = new SimpleState(window.environment || '');
export const marketCode = new SimpleState('');
export const baseUrlOrder = new SimpleState('');
export const baseUrlOrderDetails = new SimpleState('');
export const baseUrlFulfillment = new SimpleState('');
export const baseUrlPosition = new SimpleState('');
export const baseUrlOrderApp = new SimpleState('');
export const baseUrlTransport = new SimpleState('');
