import { Button, Combobox, MessageBox, ToggleSwitch } from '@schuettflix/react-components';
import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useAllEmployees } from '../../../hooks/api';
import { useGlobalVariable } from '../../../hooks/globalVariable';
import { useSubForm } from '../form';
import { SettingsFields } from './common';
import { Features } from './Features';
import { FieldError } from '@/pages/Magic/components/FieldError';
export function SettingsEdit(props) {
    const { t } = useTranslation();
    const subForm = useSubForm(SettingsFields, {
        defaultValues: props.defaultValue,
        mode: 'all',
    });
    const { control, handleSubmit, trigger, formState: { errors }, } = subForm;
    useEffect(() => {
        trigger();
    }, [trigger]);
    // Employees
    const employees = useAllEmployees();
    const employeeOptions = useMemo(() => employees.data?.map(u => ({
        label: `${u.firstName} ${u.lastName}`,
        description: u.email,
        value: u.id,
    })), [employees.data]);
    // KAM
    const rawList = useGlobalVariable('platform_key_account_list');
    const kamOptions = useMemo(() => rawList.data
        ?.split(',')
        .map(i => i.trim())
        .filter(Boolean)
        .map(i => ({ value: i, label: i })), [rawList]);
    // return
    const isLoading = employees.isLoading || rawList.isLoading;
    if (isLoading) {
        return <Loader />;
    }
    return (<form onSubmit={handleSubmit(data => props.onSave(data))}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col">
                <p className="font-copy-lg pb-8 pt-16">{t('pages.organization.form.settings.headline')}</p>
                <div className="flex flex-col gap-4 pb-6">
                    <MessageBox type="info">{t('pages.organization.form.settingNotSeenByOrganization')}</MessageBox>

                    {props.defaultValue.isExported ? (
        // TODO: Add date and time to message
        <MessageBox type="info">{t('pages.organization.form.organizationHasBeenExported')}</MessageBox>) : (<MessageBox type="info">{t('pages.organization.form.organizationNotYetExported')}</MessageBox>)}
                </div>
                <div>
                    <p className="font-copy-md-strong pb-2">
                        {t('pages.organization.form.settings.contactPerson.title')}
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                        {employeeOptions && (<>
                                <div>
                                    <Controller control={control} name="salesStaff.id" render={({ field }) => (<Combobox {...field} options={employeeOptions} label={t('components.organizationForm.employees.salesStaff')} placeholder={t('components.organizationForm.employees.searchSalesStaff')}/>)}/>
                                    <FieldError error={errors.salesStaff?.id}/>
                                </div>
                                <div>
                                    <Controller control={control} name="accountManager.id" render={({ field }) => (<Combobox {...field} options={employeeOptions} label={t('components.organizationForm.employees.accountManager')} placeholder={t('components.organizationForm.employees.searchAccountManager')}/>)}/>
                                    <FieldError error={errors.accountManager?.id}/>
                                </div>
                            </>)}
                        {kamOptions && (<Controller control={control} name="keyAccount" render={({ field }) => (<Combobox {...field} className="col-span-2" options={[
                    {
                        label: t('components.organizationForm.notSetSelectBoxLabel'),
                        value: null,
                    },
                    ...kamOptions,
                ]} label={t('components.organizationForm.keyAccountLabel')}/>)}/>)}
                    </div>
                </div>

                <div>
                    <p className="font-copy-md-strong pb-2 pt-6">
                        {t('components.organizationForm.constructionProjectSettings.headline')}
                    </p>
                    <Controller control={control} name="costcenterMandatory" render={({ field }) => (<ToggleSwitch {...field} label={t(field.value
                ? 'components.organizationForm.constructionProjectSettings.costcenterMandatory'
                : 'components.organizationForm.constructionProjectSettings.costcenterOptional')} size="sm" onChange={value => field.onChange(value)} checked={field.value}/>)}/>
                    <p className="font-copy-md-strong pb-2 pt-6">
                        {t('components.organizationForm.organizationLock.headline')}
                    </p>
                    <div className="grid grid-cols-2 gap-4 pb-6">
                        <Controller control={control} name="isActive" render={({ field }) => (<ToggleSwitch {...field} label={t(field.value
                ? 'components.organizationForm.organizationLock.lockInactive'
                : 'components.organizationForm.organizationLock.lockActive')} size="sm" onChange={value => field.onChange(!value)} checked={!field.value}/>)}/>
                        <Controller control={control} name="isBlocked" render={({ field }) => (<ToggleSwitch {...field} label={t(field.value
                ? 'components.organizationForm.organizationLock.organizationBlocked'
                : 'components.organizationForm.organizationLock.organizationNotBlocked')} size="sm" onChange={value => field.onChange(value)} checked={field.value}/>)}/>
                    </div>
                </div>

                <hr className="border-divider pb-6"/>

                {/* FEATURES */}
                <div className="flex flex-col gap-4">
                    <p className="font-copy-md">{t('pages.organization.form.settingsDescription')}</p>
                    <MessageBox type="error">{t('pages.organization.form.hintNoRevertingPossible')}</MessageBox>
                    <Features form={subForm} isNew={props.isNew}/>
                </div>
                <div className="flex justify-center gap-4 pt-8">
                    <Button type="reset" onClick={() => props.onCancel()} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                    <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                </div>
            </div>
        </form>);
}
