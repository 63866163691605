import { BrowserService } from '@/services/Browser/BrowserService';
import { useVuexGetter } from '@/reactBridge/useVuex';
import { useCallback } from 'react';
import store from '@/store';
import { useLaunchDarkly } from '@/reactBridge/useLaunchDarkly';
export function useDirectFulfillment(position) {
    const enableDirectFulfillment = useLaunchDarkly('enableDirectFulfillment');
    const genericOrderUrl = useVuexGetter(store, useCallback(getters => getters['platform/genericOrderUrl'], []));
    const openDirectFulfillmentPage = () => {
        return BrowserService.open(`${genericOrderUrl}/position/${position.id}/fulfillment`, '_self');
    };
    const isDirectFulfillmentAllowed = enableDirectFulfillment && position.allowManualFulfillment;
    return { openDirectFulfillmentPage, isDirectFulfillmentAllowed };
}
