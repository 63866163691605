import { useQuery } from '@tanstack/react-query';
import apiService from '@/services/Api/ApiService';
function getProductSchemaListQueryKey() {
    return ['product-schema-list'];
}
export function useProductSchemaList() {
    return useQuery({
        queryKey: getProductSchemaListQueryKey(),
        queryFn: () => apiService.get('/v1/product-schemas').then(res => res.data),
    });
}
export function invalidateProductSchemaListQuery(queryClient) {
    void queryClient.invalidateQueries(getProductSchemaListQueryKey());
}
