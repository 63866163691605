<template>
    <LayoutPage
        class="project-position-view"
        screen-name="client-constructionproject-position-detail"
        :parent-route="parentRoute"
        :back-title="pageSubTitle"
    >
        <template #pageTitle>
            <div>
                {{ pageTitle }}
            </div>
        </template>
        <template #mobileHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem v-if="parentRoute" button @click="$router.push(parentRoute)">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>
                <template #headline>{{ pageTitle }}</template>
                <template #subline>
                    <Words small muted>{{ pageSubTitle }}</Words>
                </template>
                <template #right>
                    <HeaderBarItem>
                        <FilterSort
                            :sort-by="orderListing.filter.sortBy"
                            :sort-direction="orderListing.filter.sortDirection"
                            :sort-options="orderListing.sortOptions"
                            :option-label-renderer="value => $t(`order.sorting.${value}`)"
                            @sort-by-update="orderListing.filter.sortBy = $event"
                            @sort-direction-update="orderListing.filter.sortDirection = $event"
                            @updated-sorting="orderListing.refreshList()"
                        />
                    </HeaderBarItem>
                    <HeaderBarItem>
                        <ProjectPositionContextMenu v-if="projectPosition" :project-position="projectPosition" />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <LoadingSpinner v-if="isLoading" block dark />

        <template v-else>
            <SfPanelManager
                class="md:max-h-[calc(100vh-153px)] lg:-my-[20px] lg:max-h-[calc(100vh-82px)] lg:min-h-[calc(100vh-82px)] lg:[&>:not(:first-child)]:py-4"
            >
                <template #default="{ panelControl }">
                    <div
                        class="overflow-y-auto overflow-x-clip md:max-h-[calc(100vh-153px)] lg:max-h-[calc(100vh-82px)]"
                    >
                        <PageMetaHead>
                            <template #item>
                                <SfInfoWithContext
                                    v-if="project.client"
                                    :context="$t('pages.project.position.view.info.client')"
                                    important
                                    class="truncate"
                                >
                                    <span class="font-copy-md-strong">
                                        {{ project.client.name }}
                                    </span>
                                </SfInfoWithContext>
                                <SfInfoWithContext
                                    v-if="projectPosition.loadingName || projectPosition.loadingAddress"
                                    :context="$t('pages.project.position.view.info.loading')"
                                    important
                                    class="truncate"
                                >
                                    <template #default>
                                        <span v-if="projectPosition.loadingName" class="font-copy-md-strong">
                                            {{ projectPosition.loadingName }}
                                        </span>
                                        <span v-if="!projectPosition.loadingName && projectPosition.loadingAddress">
                                            <FormattedAddress
                                                class="font-copy-md-strong"
                                                :address="projectPosition.loadingAddress"
                                            />
                                        </span>
                                    </template>
                                    <template v-if="projectPosition.producerNumber" #subcontent>
                                        <span class="font-numbers-sm text-subdued">
                                            {{ $t('components.projectPositionListBlock.label.producerNumber') }}
                                            {{ projectPosition.producerNumber }}
                                        </span>
                                    </template>
                                </SfInfoWithContext>
                                <SfInfoWithContext
                                    v-if="projectPosition.unloadingName || projectPosition.unloadingAddress"
                                    :context="$t('pages.project.position.view.info.unloading')"
                                    important
                                    class="truncate"
                                >
                                    <template #default>
                                        <span v-if="projectPosition.unloadingName" class="font-copy-md-strong">
                                            {{ projectPosition.unloadingName }}
                                        </span>

                                        <span v-if="!projectPosition.unloadingName && projectPosition.unloadingAddress">
                                            <FormattedAddress
                                                class="font-copy-md-strong"
                                                :address="projectPosition.unloadingAddress"
                                            />
                                        </span>
                                    </template>
                                    <template
                                        v-if="projectPosition.factory && projectPosition.factory.disposerNumber"
                                        #subcontent
                                    >
                                        <span class="font-numbers-sm text-subdued">
                                            {{ $t('components.projectPositionListBlock.label.disposerNumber') }}
                                            {{ projectPosition.factory.disposerNumber }}
                                        </span>
                                    </template>
                                </SfInfoWithContext>
                                <SfInfoWithContext
                                    v-if="projectPosition.factory && !projectPosition.isDisposal"
                                    :context="$t('pages.project.position.view.info.factoryNameLabel')"
                                    important
                                    class="truncate"
                                >
                                    <span v-if="projectPosition.factory" class="font-copy-md-strong">
                                        {{ projectPosition.factory.name }}
                                    </span>
                                </SfInfoWithContext>
                                <SfInfoWithContext
                                    v-if="projectPosition.constructionSite && !projectPosition.isDisposal"
                                    :context="$t('pages.project.position.view.info.constructionSiteLabel')"
                                    important
                                    class="truncate"
                                >
                                    <span v-if="projectPosition.constructionSite" class="font-copy-md-strong">
                                        {{ projectPosition.constructionSite.name }}
                                    </span>
                                </SfInfoWithContext>
                                <SfInfoWithContext
                                    v-if="projectPosition.carrier"
                                    :context="$t('pages.project.position.view.info.carrier')"
                                    important
                                    class="truncate"
                                >
                                    <template #default>
                                        <span class="font-copy-md-strong">
                                            {{ projectPosition.carrier.name }}
                                        </span>
                                    </template>
                                    <template v-if="projectPosition.carrier.carrierNumber" #subcontent>
                                        <span class="font-numbers-sm text-subdued">
                                            {{ $t('components.projectPositionListBlock.label.carrierNumber') }}
                                            {{ projectPosition.carrier.carrierNumber }}
                                        </span>
                                    </template>
                                </SfInfoWithContext>
                            </template>

                            <template #tags>
                                <SfTag
                                    v-for="tag in projectPosition.tags"
                                    :key="tag.label"
                                    class="whitespace-nowrap"
                                    :type="tag.type"
                                    :subdued="tag.type === 'default' && tag.label.includes('inactive')"
                                >
                                    {{ $t(tag.label) }}
                                </SfTag>
                            </template>
                            <template #menu>
                                <ProjectPositionContextMenu :project-position="projectPosition" />
                            </template>
                        </PageMetaHead>

                        <div
                            v-if="projectPosition.disposalProofNumber"
                            class="card mb-6 flex flex-col rounded md:flex-row md-max:mx-4"
                        >
                            <span class="mr-4 flex items-center sm-max:mb-2">
                                {{ $t('disposal.disposalCertificateNumber.placeholder') }}
                            </span>
                            <ReactBridge
                                :react-component="DisposalDocumentNumber"
                                :props="{
                                    documentNumber: projectPosition.disposalProofNumber,
                                    context: 'bordered',
                                    emptyState: false,
                                }"
                                class="mb-[-4px]"
                                @copyToClipboard="copyToClipboard"
                            />
                        </div>

                        <ProjectPositionMainInfo
                            :project="project"
                            :project-position="projectPosition"
                            @showQuotePrices="openPricesModal"
                            @openAnalysis="openAnalysis"
                        />

                        <div class="project-position-view__filter-block mb-8 mt-12">
                            <RoundedTabNavigation
                                :value="orderListing.activeRestriction"
                                :tabs="orderListing.restrictionsLabelSet"
                                @input="orderListing.selectRestriction($event)"
                            />
                            <Card spaceless>
                                <FilterBox
                                    v-model="orderListing.filter"
                                    :default-filter="orderListing.defaultFilter"
                                    :forced-filter="orderListing.restriction"
                                    :endpoint="orderListing.endpoint"
                                    inline-mode
                                    no-padding
                                    @update="orderListing.refreshList(false, true, true)"
                                >
                                    <template #default="filterScope">
                                        <FilterSortPagination
                                            :result="orderListing.result"
                                            :filter="filterScope.filter"
                                            :sort-options="orderListing.sortOptions"
                                            :option-label-renderer="value => $t(`order.sorting.${value}`)"
                                            :hide-sort="!$root.isDesktop"
                                            show-refresh
                                            spaceless
                                            :loading="orderListing.isLoading"
                                            @refresh="orderListing.refreshList(false, true, true)"
                                            @pageNumberUpdated="orderListing.updatePageNumber($event)"
                                        >
                                            <div v-if="$root.isDesktop" />
                                        </FilterSortPagination>
                                    </template>
                                </FilterBox>
                            </Card>
                        </div>

                        <div class="mb-4 mt-12 pl-4 lg:pl-0">
                            <span class="font-copy-md-strong">
                                {{ $tc('pages.project.position.orders.headline', orderListing.resultCount) }}
                            </span>
                        </div>

                        <template v-if="orderListing.resultCount">
                            <Card
                                v-for="order in orderListing.resultItems"
                                :key="order.id"
                                :spaceless-x="$root.isDesktop"
                            >
                                <OrderItemBlock :order-view="order" @click="openOrder(order)" />
                            </Card>
                            <Card spaceless>
                                <Pagination
                                    align-right
                                    :result="orderListing.result"
                                    @pageNumberUpdated="orderListing.updatePageNumber($event)"
                                />
                            </Card>
                        </template>
                        <Hint v-else center transparent>
                            {{ $t('order.list.noResults') }}
                        </Hint>
                        <PanelControlContextProvider
                            v-if="isGenericOrderDetailsSubPageOpen"
                            :panel-control="panelControl"
                        >
                            <router-view />
                        </PanelControlContextProvider>
                    </div>
                </template>
            </SfPanelManager>
        </template>

        <ModalBox ref="pricesModal" :headline="$t('pages.project.position.quotePrices.modal')" spaceless>
            <ProjectPositionQuotePrices
                v-if="showQuotePrices"
                :view="projectPosition.quotationPrices"
                :project-type="projectPosition.type"
                :vehicle-classes="projectPosition.vehicleClasses"
                :billing-method="projectPosition.billingMethod"
            />

            <div v-if="showCostPreview" class="project-position-view__modal-section">
                <ProjectPositionCostPreview
                    :material="costPreview.material"
                    :transports="costPreview.transports"
                    :show-full-prices="showPurchasePrices"
                    :billing-method="projectPosition.billingMethod"
                />
            </div>

            <ProjectPositionAdditionalItems
                v-if="showCustomPositions"
                class="project-position-view__modal-section"
                :show-credit-to="isPlatform"
                :custom-positions="projectPosition.customPositions"
                :show-full-prices="showPurchasePrices"
            />
        </ModalBox>

        <Flyout :route="orderViewRoute" size="small" />
        <Flyout :route="projectPositionAnalysisRoute" size="small" />
    </LayoutPage>
</template>

<script>
import { defineComponent } from 'vue';
import { CONTEXT_PLATFORM } from '@/constants/context';

import FilterableListing from '@/services/FilterableListing/FilterableListing';
import OrderApi from '@/services/Api/Order';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import Toaster from '@/services/Toaster';
import NativeClipboard from '@/services/Clipboard/NativeClipboard';
import { EVENT_PROJECT_POSITION_UPDATED, EVENT_PROJECT_POSITION_CLOSED } from '@/constants/events';

import routeContext from '@/plugins/mixins/routeContext';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import ProjectPositionView from '@/models/ProjectPositionView';
import ProjectFacadeView from '@/models/ProjectFacadeView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';

import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSort from '@/components/Filter/FilterSort';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Flyout from '@/components/Layout/Flyout';
import FormattedAddress from '@/components/FormattedAddress';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ModalBox from '@/components/Modal/ModalBox';
import OrderItemBlock from '@/components/List/OrderItemBlock';
import PageMetaHead from '@/components/Layout/PageMetaHead';
import Pagination from '@/components/Pagination';
import DisposalDocumentNumber from '@/_components/DisposalDocumentNumber/DisposalDocumentNumber';
import ProjectPositionAdditionalItems from '@/pages/Project/components/ProjectPositionAdditionalItems';
import ProjectPositionContextMenu from '@/pages/Project/components/ProjectPositionView/ProjectPositionContextMenu';
import ProjectPositionMainInfo from '@/pages/Project/components/ProjectPositionView/ProjectPositionMainInfo';
import ProjectPositionQuotePrices from '@/pages/Project/components/ProjectPositionQuotePrices';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import Words from '@/components/Typography/Words';
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import PanelControlContextProvider from '@/pages/Order/Context/PanelControlContext.vue';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import ProjectPositionCostPreview from '@/pages/Project/components/ProjectPositionView/ProjectPositionCostPreview';
import OrderItemBlockView from '@/components/List/OrderItemBlockView';

import { SfInfoWithContext, SfPanelManager, SfTag } from '@schuettflix/vue-components';
import { CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE } from '@/constructionProjects/constants';
import { useLd } from '@/services/LaunchDarkly';

export default defineComponent({
    name: 'ConstructionProjectPositionDetailsPage',
    components: {
        Card,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        Flyout,
        FormattedAddress,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LoadingSpinner,
        ModalBox,
        OrderItemBlock,
        PageMetaHead,
        Pagination,
        ProjectPositionAdditionalItems,
        ProjectPositionContextMenu,
        ProjectPositionCostPreview,
        ProjectPositionMainInfo,
        ProjectPositionQuotePrices,
        RoundedTabNavigation,
        Words,
        ReactBridge,

        ArrowIcon,
        SfInfoWithContext,
        SfPanelManager,
        PanelControlContextProvider,
        SfTag,
    },
    mixins: [eventHubMixin, statefulMixin, routeContext],
    props: {
        positionId: {
            type: [Number, String],
            default: null,
        },
        parentRoute: {
            type: String,
            default: null,
        },
    },
    setup() {
        const isGenericOrderDetailsForClientsEnabled = useLd('monolith-display-generic-order-details-for-clients');
        const isGenericOrderDetailsForLegacyOrdersEnabled = useLd(
            'monolith-display-generic-order-details-for-legacy-orders'
        );
        return {
            isGenericOrderDetailsForLegacyOrdersEnabled,
            isGenericOrderDetailsForClientsEnabled,
        };
    },
    data() {
        const orderListing = new FilterableListing({
            namespace: 'positionOrder',
            endpoint: OrderApi,
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'created',
                sortDirection: 'desc',
            },
            defaultRestriction: {
                projectPosition: this.positionId,
            },
            sortOptions: ['created', 'orderNumber', 'client', 'supplier', 'invoiceStatus'],
            restrictionLabelRenderer: (restrictionKey, count) => {
                return this.$t(`pages.project.position.orders.tab.label.${restrictionKey}`, { count: count });
            },
            transformCallback: item => OrderItemBlockView.create(item),
        });

        if (this.$can('haveProjectManagement')) {
            orderListing.addRestriction('new', {
                status: 'new',
            });
            orderListing.addRestriction('in_progress', {
                status: 'in_progress',
            });
        } else {
            orderListing.addRestriction('in_progress', {
                status: ['new', 'in_progress'],
            });
        }

        orderListing.addRestriction('closed', {
            status: 'closed',
        });

        orderListing.addRestriction('canceled', {
            status: 'canceled',
        });

        return {
            project: null,
            projectPosition: null,
            costPreview: null,
            orderListing,
            orderViewRoute: `${CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE}__order-view`,
            projectPositionAnalysisRoute: `${CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE}__analysis`,
            DisposalDocumentNumber,
        };
    },
    computed: {
        isGenericOrderDetailsSubPageOpen() {
            return this.isGenericOrderDetailsForClientsEnabled && this.$route.name.includes('generic-order-details');
        },
        pageTitle() {
            return this.$t('pages.project.position.view.title', {
                number: this.projectPosition === null ? 'P…' : this.projectPosition.number,
            });
        },
        pageSubTitle() {
            return this.$t('pages.project.position.view.subtitle', {
                name: this.project === null ? 'P…' : this.project.name,
            });
        },
        isLoading() {
            return this.isPending() || !this.project || !this.projectPosition;
        },
        showPurchasePrices() {
            return this.$can('haveProjectManagement');
        },
        showQuotePrices() {
            return this.projectPosition?.quotationPrices && this.showPurchasePrices;
        },
        showCostPreview() {
            return this.costPreview;
        },
        showCustomPositions() {
            return this.projectPosition?.customPositions?.length > 0;
        },
        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
    },
    watch: {
        $route() {
            this.refreshProjectPosition();
            this.orderListing.onRouteChanged();
        },
    },
    created() {
        this.refreshProjectPosition();
        this.orderListing.pairComponent(this);
        this.orderListing.refreshList(true);

        this.subscribe(EVENT_PROJECT_POSITION_UPDATED, () => {
            this.refreshProjectPosition();
        });

        this.subscribe(EVENT_PROJECT_POSITION_CLOSED, () => {
            this.refreshProjectPosition();
        });
    },
    methods: {
        async refreshProjectPosition() {
            await this.stateful('refreshProjectPosition', async () => {
                try {
                    const result = await ProjectPositionApi.getOneById(this.positionId);
                    this.project = ProjectFacadeView.create(result.project);
                    this.projectPosition = ProjectPositionView.create(result);

                    this.costPreview = {
                        material: MaterialPricingView.createFromProjectPositionPayload(result),
                        transports: TransportPricingCollectionView.createFromVehicleClasses(result.vehicleClasses),
                    };
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
        async copyToClipboard() {
            const nativeClipboard = new NativeClipboard(
                this.$t('pages.order.orderView.overview.disposalIdentifiers.copySuccess', {
                    identifier: this.$t('disposal.disposalCertificateNumber.title'),
                }),
                this.$t('pages.order.orderView.overview.disposalIdentifiers.copyError', {
                    identifier: this.$t('disposal.disposalCertificateNumber.title'),
                })
            );
            return nativeClipboard.writeToClipboard('string', this.projectPosition.disposalProofNumber);
        },
        openOrder(order) {
            // new app-order-details
            const shouldBeDisplayedInAppOrderDetails = (() => {
                if (!this.isGenericOrderDetailsForClientsEnabled) return false;
                if (order.isGenericOrder) return true;
                return this.isGenericOrderDetailsForLegacyOrdersEnabled && order.isMonolithOrderThroughGenericOrder;
            })();
            if (shouldBeDisplayedInAppOrderDetails)
                return this.$router
                    .push({
                        name: this.$root.findRouteName('generic-order-details'),
                        params: { orderId: order.genericOrderId },
                        query: this.$route.query,
                    })
                    .catch(() => {});

            // old order-view
            this.$router.push({
                name: this.orderViewRoute,
                params: { orderId: order.id },
                query: this.$route.query,
            });
        },
        openAnalysis() {
            this.$router.push({
                name: this.projectPositionAnalysisRoute,
                query: this.$route.query,
            });
        },
        openPricesModal() {
            this.$refs.pricesModal.$emit('open');
        },
    },
});
</script>

<style lang="scss" scoped>
.project-position-view__headline {
    margin-bottom: 30px;
    padding-left: 15px;
}

.project-position-view__modal-section {
    padding: 20px;
    border-top: $border-solid-2px;
}
</style>
